// src/pages/AdminPanel/pages/store/StoreInfo/EditForm.jsx

import React, { useState } from 'react';
import GrayButton from '../../../../../components/GrayButton';
import { useTranslation } from 'react-i18next';
import AdminEditFormInput from '../../../components/AdminEditFormInput';
import AdminEditFormSelect from '../../../components/AdminEditFormSelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ROLES } from '../../../utils/formatForTables';
import AdminApi from '../../../../../services/Axios/AdminApi';
import AdminEditFormDate from '../../../components/AdminEditFormDate';
import dayjs from 'dayjs';
import showNotification from '../../../../../utils/showNotification';

function EditForm({ store, onSave, onCancel }) {
  const { t } = useTranslation();
  const [malls, setMalls] = useState([]);
  const [storeManagers, setStoreManagers] = useState([]);
  const taxes = [
    { value: 'Reduced', label: t('reduced') },
    { value: 'Fixed', label: t('fixed') },
  ];

  const initialValue = {
    name: store.name,
    mall: store.mall?.id,
    storeManager: store.storeManager?.id,
    tax: store.tax,
    startDate: store.startDate ? dayjs(store.startDate) : null,
    endDate: store.endDate ? dayjs(store.endDate) : null,
  };

  React.useEffect(() => {
    AdminApi.getMallsList({ limit: 149 }).then((res) => {
      setMalls(res.data);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim('validation.trimError')
      .min(2, t('validation.tooShort'))
      .max(35, t('validation.tooLong'))
      .matches(/^[a-zA-ZæøåÆØÅ0-9 ]+$/, t('validation.shouldBeText'))
      .required(t('validation.required')),
    mall: Yup.string().required(t('validation.required')),
    tax: Yup.string().required(t('validation.required')),
  });

  const onInnerSubmit = (values, { setSubmitting }) => {
    if (values.startDate && values.endDate) {
      if (dayjs(values.endDate).isBefore(values.startDate)) {
        showNotification({
          content: "End date can't be before start date !",
          type: 'error',
        });
        setSubmitting(false);
        return;
      }
    }
    const formattedValues = {
      name: values.name,
      mallId: values.mall,
      storeManagerId: values.storeManager || undefined,
      tax: values.tax,
      startDate: values.startDate
        ? values.startDate.format('YYYY-MM-DD')
        : null,
      endDate: values.endDate ? values.endDate.format('YYYY-MM-DD') : null,
    };
    return onSave(formattedValues);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: initialValue,
    onSubmit: onInnerSubmit,
    validationSchema,
  });

  React.useEffect(() => {
    if (values.mall) {
      AdminApi.getUsers({
        role: ROLES.STORE_MANAGER,
        limit: 149,
        available: true,
      }).then((res) => {
        setStoreManagers(res.data);
      });
    }
  }, [values.mall]);

  return (
    <div className='EditForm'>
      <AdminEditFormInput
        name={t('adminTable.storeName')}
        placeholder={t('adminTable.storeName')}
        value={values.name}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        error={touched.name && errors.name}
      />
      <AdminEditFormSelect
        name={t('adminTable.mall')}
        placeholder={t('adminTable.mall')}
        value={values.mall}
        options={malls.map((mall) => ({
          value: mall.id,
          label: mall.name,
        }))}
        onChange={(val) => setFieldValue('mall', val)}
        onBlur={handleBlur('mall')}
        error={touched.mall && errors.mall}
      />
      <AdminEditFormSelect
        name={t('adminTable.storeManager')}
        placeholder={t('adminTable.storeManager')}
        value={
          values.storeManager !== store.storeManager?.id
            ? values.storeManager
            : store.storeManager
              ? store.storeManager?.name + ' ' + store.storeManager?.surname
              : ''
        }
        onChange={(val) => setFieldValue('storeManager', val)}
        options={storeManagers.map((store) => ({
          value: store.id,
          label: store?.name + ' ' + store?.surname,
        }))}
        onBlur={handleBlur('storeManager')}
        error={touched.storeManager && errors.storeManager}
        disabled={values.endDate ? values.endDate.isBefore(dayjs()) : false}
      />
      <AdminEditFormSelect
        name={`${t('adminTable.tax')}`}
        placeholder={t('adminTable.tax')}
        value={values.tax}
        options={taxes}
        error={touched.tax && errors.tax}
        onBlur={handleBlur('tax')}
        onChange={(val) => setFieldValue('tax', val)}
      />
      <AdminEditFormDate
        name={t('start_Date')}
        value={values.startDate}
        onChange={(val) => setFieldValue('startDate', val)}
        error={errors.startDate}
      />
      <AdminEditFormDate
        name={t('end_Date')}
        value={values.endDate}
        onChange={(val) => setFieldValue('endDate', val)}
        error={errors.endDate}
      />
      <div className='EditForm__buttons'>
        <GrayButton onClick={onCancel}>{t('cancel')}</GrayButton>
        <GrayButton onClick={handleSubmit} loading={isSubmitting}>
          {t('save')}
        </GrayButton>
      </div>
    </div>
  );
}

export default EditForm;
