// src/components/IncomeTable/components/DateSelector/DateSelectorButton.jsx

import React from 'react';
import { Popover } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import DateSelector from '.';
import { TableContext } from '../Table/TableContext';

// Function to format date from 'YYYY-MM-DD' to 'DD.MM.YY'
const formatDate = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}.${month}.${year.slice(2)}`;
};

function DateSelectorButton({ createAt, column }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { tableUID } = React.useContext(TableContext);

  return (
    <Popover
      content={
        <DateSelector
          createAt={createAt}
          tableUID={tableUID}
          column={column}
          close={() => setIsOpen(false)}
        />
      }
      placement='bottom'
      trigger='click'
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {column ? (
        <span>
          {formatDate(column.from)} - {formatDate(column.to)}
        </span>
      ) : (
        <PlusCircleOutlined />
      )}
    </Popover>
  );
}

export default DateSelectorButton;
