// src/services/Redux/filtersReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setAllMalls = (payload) => createAction(ActionTypes.SET_ALL_MALLS, payload);
export const setAllMallsUsers = (payload) => createAction(ActionTypes.SET_ALL_MALLS_USERS, payload);
export const setAllStores = (payload) => createAction(ActionTypes.SET_ALL_STORES, payload);
export const setAllStoresGroup = (payload) => createAction(ActionTypes.SET_ALL_STORES_GROUP, payload);
