// src/pages/AdminPanel/components/AdminEditFormInput/index.jsx

import React from 'react';
import './styles.scss';
import { TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';

function AdminEditFormInput({
  value,
  onChange,
  placeholder = 'Placeholder',
  name = 'Field name',
  error = '',
  onBlur,
}) {
  return (
    <div className='AdminEditFormInput'>
      <div className='AdminEditFormInput__name'>{name}</div>
      <div>
        <TextField
          className='AdminEditFormInput__input'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          variant='filled'
          onBlur={onBlur}
          inputProps={{
            style: error ? { borderColor: '#D92828' } : {},
          }}
        />
        {error && <div className='AdminEditFormInput__error'>{error}</div>}
      </div>
    </div>
  );
}

function AdminEditFormPhoneInput({
  value,
  onChange,
  placeholder = 'Placeholder',
  name = 'Field name',
  error = '',
  onBlur,
}) {
  return (
    <div className='AdminEditFormInput'>
      <div className='AdminEditFormInput__name'>{name}</div>
      <div>
        <ReactInputMask
          mask='+47 999 99 999'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={error ? { borderColor: '#D92828' } : {}}
          disabled={false}
          onBlur={onBlur}
          maskChar='_'
        >
          {() => (
            <TextField
              className='AdminEditFormInput__input phone'
              placeholder={placeholder}
              variant='filled'
            />
          )}
        </ReactInputMask>
        {error && <div className='AdminEditFormInput__error'>{error}</div>}
      </div>
    </div>
  );
}

export { AdminEditFormPhoneInput };

export default AdminEditFormInput;
