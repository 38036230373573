// src/services/Saga/filters/index.js

import { call, put, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import {
  setAllMalls,
  setAllMallsUsers,
  setAllStores,
  setAllStoresGroup,
} from '../../Redux/filtersReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

function buildQueryParams(payload) {
  return new URLSearchParams({
    page: 1,
    limit: 100,
    ...(payload?.mallId && { mallIds: payload.mallId }),
    ...(payload?.storeGroupsId && { storeGroupsIds: payload.storeGroupsId }),
    ...(payload?.storeId && { storeIds: payload.storeId }),
    ...(payload?.tax && { tax: payload.tax }),
    ...(payload?.order && { order: payload.order }),
  }).toString();
}

function* fetchData(url, setDataAction, logMessage) {
  try {
    const res = yield call(axiosInstance.get, url);
    console.log(logMessage, res.data);
    yield put(setDataAction(res));
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
  }
}

export function* watcherGetAllMalls() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS, workerGetAllMalls);
}

export function* workerGetAllMalls({ payload }) {
  const queryParams = buildQueryParams(payload);
  const url = `${MAIN_URL}/v1/malls/availables?${queryParams}`;
  yield fetchData(url, setAllMalls, 'Fetched malls:');
}

export function* watcherGetAllMallsUsers() {
  yield takeEvery(ActionTypes.GET_ALL_MALLS_USERS, workerGetAllMallsUsers);
}

export function* workerGetAllMallsUsers() {
  const url = `${MAIN_URL}/v1/malls/?page=1&limit=100`;
  yield fetchData(url, setAllMallsUsers, 'Fetched malls users:');
}

export function* watcherGetAllStores() {
  yield takeEvery(ActionTypes.GET_ALL_STORES, workerGetAllStores);
}

export function* workerGetAllStores({ payload }) {
  const queryParams = buildQueryParams(payload);
  const url = `${MAIN_URL}/v1/stores/availables?${queryParams}`;
  yield fetchData(url, setAllStores, 'Fetched stores:');
}

export function* watcherGetAllGroups() {
  yield takeEvery(ActionTypes.GET_ALL_STORES_GROUP, workerGetAllGroups);
}

export function* workerGetAllGroups({ payload }) {
  const queryParams = buildQueryParams(payload);
  const url = `${MAIN_URL}/v1/store-groups?${queryParams}`;
  yield fetchData(url, setAllStoresGroup, 'Fetched store groups:');
}
