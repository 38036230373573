// src/pages/AdminPanel/components/AdminFormSelect/index.jsx

import React from 'react';
import './styles.scss';
import { Select } from 'antd';
import Triangle from '../../../../assets/icons/Triangle';

function AdminFormSelect({
  value,
  placeholder,
  options = [],
  onChange,
  name = 'Field name',
  disabled = false,
  onBlur,
  error = '',
}) {
  return (
    <div className='AdminFormSelect' style={{ opacity: disabled ? 0.4 : 1 }}>
      <div className='AdminFormSelect__name'>{name}</div>
      <div>
        <Select
          value={value === '' ? undefined : value}
          style={error ? { borderColor: '#d92828' } : { minWidth: 144 }}
          onChange={onChange}
          dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)', zIndex: 11000 }}
          placeholder={placeholder}
          options={options}
          className='AdminFormSelect__select'
          disabled={disabled}
          suffixIcon={<Triangle />}
          onDropdownVisibleChange={(open) => {
            if (!open) onBlur();
          }}
        />
        {error && <div className='AdminFormSelect__error'>{error}</div>}
      </div>
    </div>
  );
}

export default AdminFormSelect;
