// src/components/IncomeTable/components/ToggleableEye/index.jsx

import React from 'react';
import './styles.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

function ToggleableEye({ isDisabled, onClick, style = {} }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick();
      }}
      className={`ToggleableEye ${isDisabled && 'disabled'}`}
      style={style}
    >
      {isDisabled ? (
        <EyeInvisibleOutlined
          style={{ fontSize: 16, color: '#797979' }}
          twoToneColor='#797979'
        />
      ) : (
        <EyeOutlined style={{ fontSize: 16 }} />
      )}
    </div>
  );
}

export default ToggleableEye;
