// src/App.js

import React from 'react';
import { Routes } from './routes/Routes';
import 'antd/dist/reset.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <div className='app-wrapper'>
    <Routes />
    <ToastContainer />
  </div>
);

export default App;
