// src/components/IncomeTable/components/TableHeader/Select.jsx

import React, { useContext, useLayoutEffect, useState } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import { TableContext } from '../Table/TableContext';
import { getShouldDisplayDiff } from '../../utils/getDiff';

const getRight = (windowWidth, biggestWidth, shownColumnsLength) => {
  const adjustedWidth = Math.abs(windowWidth - biggestWidth) - shownColumnsLength * 150;
  if (windowWidth < 1150) return adjustedWidth < 60 ? 60 : adjustedWidth;
  return 40;
};

function Select() {
  const { selectedTable, selectTable, tablesSettings } = useContext(IncomeTableContext);
  const { tableUID, isEdit, numbersDiffColumn, percentageDiffColumn } = useContext(TableContext);

  let biggestWidth = 0;
  let biggestColumns = 0;

  Object.entries(tablesSettings).forEach(([tableUID, table]) => {
    const shouldDisplayDiff = getShouldDisplayDiff(table.columns);
    const shownColumns = table.columns.filter((col) =>
      shouldDisplayDiff ? isEdit || col.isVisible : col.isVisible && !col.isDiff
    );
    const shouldDisplayAdd = isEdit && table.columns.filter((col) => !col.isDiff).length < 6;

    const shouldShowNumberDiff = shouldDisplayDiff && (isEdit || numbersDiffColumn.isVisible);
    const shouldShowPercentageDiff = shouldDisplayDiff && (isEdit || percentageDiffColumn.isVisible);

    const tableWidth =
      10 +
      (shouldDisplayAdd ? 200 : 0) +
      (shouldShowPercentageDiff ? 100 : 0) +
      (shouldShowNumberDiff ? 100 : 0) +
      shownColumns.length * 150;

    if (tableWidth > biggestWidth) biggestWidth = tableWidth;
    if (shownColumns.length > biggestColumns) biggestColumns = shownColumns.length;
  });

  const [windowWidth] = useWindowSize();

  return (
    <div
      className='TableHeader__column select'
      style={{
        right: getRight(windowWidth, biggestWidth, biggestColumns),
      }}
    >
      <div
        className={`TableHeader__selectedRadio ${selectedTable === tableUID ? 'selected' : ''}`}
        onClick={() => selectTable(tableUID)}
        style={{ marginTop: isEdit ? 0 : 8 }}
      >
        <div />
      </div>
    </div>
  );
}

export default Select;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
