// src/pages/AdminPanel/pages/store/StoresTable/useStoresTable.jsx

import React, { useMemo } from 'react';
import { formatStores } from '../../../utils/formatForTables';
import AdminApi from '../../../../../services/Axios/AdminApi';

function useStoresTable() {
  const [storesData, setStoresData] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    total: 10,
  });

  React.useEffect(() => {
    AdminApi.getStores({ page: pagination.page }).then((res) => {
      setPagination((p) => {
        if (p.page === res.page) {
          setStoresData(res?.data || []);
          return { page: res.page, total: res.total };
        }
        return p;
      });
    });
  }, [pagination.page]);

  const onStoreCreated = () => {
    setPagination((p) => ({
      ...p,
      total: p.total + 1,
      page: p.total && p.total % 10 === 0 ? p.page + 1 : p.page,
    }));
    if (pagination.total === 0 || pagination.total % 10 !== 0)
      AdminApi.getStores({ page: pagination.page }).then((res) => {
        setStoresData(res?.data || []);
      });
  };

  const onPageChange = (page) => {
    setPagination((p) => ({ ...p, page }));
  };

  const onSearchChange = (text) => {
    setPagination({
      page: 1,
      total: 10,
    });
    setSearchText(text);
  };

  const fortmatedStores = useMemo(
    () => formatStores(storesData, searchText, pagination.page),
    [storesData, searchText],
  );

  return {
    stores: fortmatedStores,
    searchText,
    isModalVisible,
    setIsModalVisible,
    pagination,
    setSearchText: onSearchChange,
    onPageChange,
    onStoreCreated,
  };
}

export default useStoresTable;
