// src/routes/Routes.jsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as AppRoutes, Route, Navigate } from 'react-router';
import { admin, auth, dashboard, reports } from './route';
import { ROLES } from '../pages/AdminPanel/utils/formatForTables';
import { getUserInfo } from '../services/Saga/loginSaga/actions';

export const Routes = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loginReducer.isLoading);
  const user = useSelector((state) => state.loginReducer.user);

  const roles = user?.roles || [];
  const hasRole = (role) => roles.includes(role);

  let routes = [...auth]; // Default to auth routes

  if (hasRole(ROLES.ADMIN)) routes = [...dashboard, ...admin, ...reports];
  if (hasRole(ROLES.STORE_MANAGER) || hasRole(ROLES.MALL_MANAGER) || hasRole(ROLES.MALL_OWNER)) {
    routes = [...dashboard, ...reports];
  }

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  if (isLoading || isLoading === undefined) return null;

  return (
    <AppRoutes>
      {routes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      <Route path='*' element={<Navigate to={routes[0].path} replace />} />
    </AppRoutes>
  );
};
