// src/pages/AdminPanel/components/AdminEditFormDate/index.jsx

import { DatePicker } from 'antd';
import React from 'react';
import './styles.scss';

import nor from 'antd/es/date-picker/locale/nb_NO';
import 'dayjs/locale/nb';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import i18next from 'i18next';

dayjs.extend(relativeTime);

function AdminEditFormDate({
  value,
  onChange,
  name = 'Field name',
  error = '',
  placeholder,
}) {
  return (
    <div className='AdminEditFormDate'>
      <div className='AdminEditFormDate__name'>{name}</div>
      <div>
        <DatePicker
          className='AdminEditFormDate__date-input'
          value={value}
          onChange={onChange}
          placeholder={placeholder || name}
          locale={i18next.languages.some((x) => x === 'nor') ? nor : undefined}
        />
        {error && <div className='AdminEditFormDate__error'>{error}</div>}
      </div>
    </div>
  );
}

export default AdminEditFormDate;
