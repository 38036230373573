// src/pages/Income/components/IncomeCalendarCell/index.jsx

import React, { useCallback, useEffect, useState, useRef } from 'react';
import Pen from '../../../../assets/icons/Pen';
import './styles.scss';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import addSpaces from '../../../../utils/addSpaces';
import axiosInstance from '../../../../services/Axios/axiosInstance';
import useOutsideClick from '../../../../utils/hooks/useOutsideClick';

// Function to transform date into day
const transformDate = (date) => (date ? dayjs(date).format('D') : '');

// Style for error indication
const errorStyle = {
  borderWidth: '1px',
  borderColor: 'red',
};

const IncomeCalendarCell = ({ item, storeInfo, onIncomeAdded }) => {
  const [isActive, setIsActive] = useState(false);
  const isFixed = storeInfo?.tax === 'Fixed';

  const [values, setValues] = useState({
    included: { error: false, value: '' },
    excluded: { error: false, value: '' },
  });

  useEffect(() => {
    setValues({
      included: { error: false, value: item.id ? item.included : '' },
      excluded: { error: false, value: item.id ? item.excluded : '' },
    });
  }, [item]);

  const addIncome = async (payload) => {
    const body = {
      storeId: payload.storeId,
      included: payload.included,
      excluded: payload.excluded,
      date: dayjs(payload.date).format('YYYY-MM-DD'),
    };

    const putBody = {
      included: payload.included,
      excluded: payload.excluded,
    };

    return payload.itemId
      ? axiosInstance.put(`/incomes/${payload.itemId}`, putBody)
      : axiosInstance.post(`/incomes`, body);
  };

  const changeHandler = async (newValues) => {
    const valuesAreZeros = +newValues.included.value === 0 && +newValues.excluded.value === 0;

    if (+newValues.included.value < +newValues.excluded.value && !valuesAreZeros) {
      setValues((prev) => ({
        included: { error: true, value: newValues.included.value },
        excluded: { error: true, value: prev.excluded.value },
      }));
    } else {
      const res = await addIncome({
        storeId: storeInfo?.id,
        included: newValues.included.value === '' ? null : +newValues.included.value,
        excluded: newValues.excluded.value === '' ? null : +newValues.excluded.value,
        date: item?.date,
        itemId: item?.id,
      });

      onIncomeAdded(res?.data?.id ? res.data : {
        storeId: storeInfo?.id,
        included: newValues.included.value === '' ? null : +newValues.included.value,
        excluded: newValues.excluded.value === '' ? null : +newValues.excluded.value,
        date: item?.date,
        id: item?.id,
      });
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), [
    item?.id,
    item?.date,
    item?.storeId,
  ]);

  const onIncludedChange = (event) => {
    const value = event.target.value.replace(/\s+/g, '').replace(/[^\d.]/g, '');
    const newValues = {
      included: { error: false, value },
      excluded: isFixed ? { error: false, value: value === '' ? '' : value / 1.25 } : values.excluded,
    };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const onExcludedChange = (event) => {
    if (isFixed) return;
    const value = event.target.value.replace(/\s+/g, '').replace(/[^\d.]/g, '');
    const newValues = { ...values, excluded: { error: false, value } };
    setValues(newValues);
    debouncedChangeHandler(newValues);
  };

  const isAfterToday = dayjs(item.date).isAfter(dayjs());
  const isActivated = isActive && !isAfterToday;
  const ref = useOutsideClick(() => isActive && setIsActive(false));
  const isSunday = dayjs(item?.date).day() === 0;

  const isDisabled = !isSunday && ((storeInfo?.endDate ? dayjs(storeInfo.endDate).add(1, 'day').isBefore(dayjs(item?.date)) : false) ||
    (storeInfo?.startDate ? dayjs(storeInfo.startDate).isAfter(dayjs(item?.date)) : false) || item.isLocked);

  const excludedRef = useRef();
  const includedRef = useRef();

  const activate = (value) => {
    if (!isDisabled) {
      setIsActive(value);
      setTimeout(() => {
        if (value === 'excluded') excludedRef.current.focus();
        if (value === 'included') includedRef.current.focus();
      }, 10);
    }
  };

  return (
    <div
      className='IncomeCalendarCell'
      style={{ gap: isActivated ? 7 : 12, opacity: isAfterToday || isDisabled ? 0.6 : 1 }}
      ref={ref}
    >
      <div className='IncomeCalendarCell__day'>{transformDate(item?.date)}</div>

      {isActivated ? (
        <>
          <div className='editableCalendarInput excluded'>
            <Pen />
            <input
              ref={excludedRef}
              value={addSpaces(values.excluded.value, { emptyValue: '' })}
              onChange={onExcludedChange}
              disabled={isFixed || isDisabled}
              style={values.excluded.error ? errorStyle : {}}
            />
          </div>
          <div className='editableCalendarInput'>
            <Pen />
            <input
              ref={includedRef}
              value={addSpaces(values.included.value, { emptyValue: '' })}
              onChange={onIncludedChange}
              disabled={isDisabled}
              style={values.included.error ? errorStyle : {}}
            />
          </div>
        </>
      ) : (
        <>
          <span className='sum' onClick={() => activate('excluded')}>
            {item.excluded !== null ? addSpaces(item.excluded) : '-'}
          </span>
          <span className='sum included' onClick={() => activate('included')}>
            {item.included !== null ? addSpaces(item.included) : '-'}
          </span>
        </>
      )}
    </div>
  );
}

export default IncomeCalendarCell;
