// useDebounce.js
import { useRef, useEffect } from 'react';

const useDebounce = (callback, delay) => {
  const debounceTimeoutRef = useRef(null);

  const debounce = (...args) => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => clearTimeout(debounceTimeoutRef.current);
  }, [delay]);

  return debounce;
};

export default useDebounce;
