// src/components/ConfirmModal/index.jsx

import { Button, Modal } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function ConfirmModal({
  onConfirm,
  onCancel,
  text = 'Are you sure ?',
  confirmBtnText = 'Confirm',
  cancelBtnText,
  confirmDoneText = '',
  confirmDoneBtnText = '',
  onConfirmDone,
}) {
  const [isConfirmDoneShown, setIsConfirmDoneShown] = React.useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState(false);

  const onInnerCancel = () => {
    onCancel();
  };

  const onInnerConfirm = async () => {
    setIsConfirmLoading(true);
    await onConfirm();
    if (confirmDoneText) setIsConfirmDoneShown(true);
    setIsConfirmLoading(false);
  };

  const onInnerConfirmDone = () => {
    if (onConfirmDone) onConfirmDone();
    else onCancel();
  };

  const onBackdropClick = () => {
    if (!isConfirmDoneShown) onCancel();
    if (isConfirmDoneShown && typeof onConfirmDone === 'function')
      onConfirmDone();
  };

  const { t } = useTranslation();

  return (
    <Modal open={true} onClose={onCancel} onBackdropClick={onBackdropClick}>
      <div className='ConfirmModal'>
        {isConfirmDoneShown ? (
          <div>
            <div className='ConfirmModal__text'>{confirmDoneText}</div>
            <div className='ConfirmModal__buttons center'>
              <LoadingButton
                style={{ width: 316 }}
                loading={isConfirmLoading}
                onClick={onInnerConfirmDone}
              >
                {confirmDoneBtnText}
              </LoadingButton>
            </div>
          </div>
        ) : (
          <div>
            <div className='ConfirmModal__text'>{text}</div>
            <div className='ConfirmModal__buttons '>
              <LoadingButton onClick={onInnerCancel}>
                {cancelBtnText || t('cancel')}
              </LoadingButton>
              <LoadingButton
                loading={isConfirmLoading}
                onClick={onInnerConfirm}
              >
                {confirmBtnText}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmModal;
