// src/assets/icons/Add.jsx

import * as React from 'react';
const Add = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke='#FAFAFA'
      strokeMiterlimit={10}
      strokeOpacity={0.72}
      d='M17.5 10c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5Z'
    />
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='M10 6.875v6.25M13.125 10h-6.25'
    />
  </svg>
);
export default Add;
