// src/components/IncomeTable/utils/isInViewport.js

const isElementInViewport = (el) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();

  return rect.top >= 0 && rect.left >= 0;
};

const isElementFullyInViewport = (el) => {
  if (!el) return false;
  const elementTop = el.getBoundingClientRect().top + window.scrollY;
  const elementBottom = elementTop + el.offsetHeight;

  const viewportTop = window.scrollY;
  const viewportBottom = viewportTop + window.innerHeight;

  return elementTop >= viewportTop && elementBottom <= viewportBottom;
};

export { isElementFullyInViewport };
export default isElementInViewport;
