// src/components/IncomeTable/components/TableMallComponent/index.jsx

import React, { useContext } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import './styles.scss';
import Triangle from '../../../../assets/icons/Triangle';
import ToggleableEye from '../ToggleableEye';
import addSpaces from '../../../../utils/addSpaces';
import { TableContext } from '../Table/TableContext';
import { getIsStoreShown, shouldDisplayPercents } from '../../utils/otherUtils';
import { useTranslation } from 'react-i18next';
import getDisabledStores, { getIsStoreDisabled } from '../../utils/getDisabledStore';

function TableMallComponent({ mall }) {
  const { toggleStoreVisible, toggleMallVisible, toggleMallMinimized } = useContext(IncomeTableContext);

  const { tableUID, tableSettings, isEdit, shownColumns, shouldDisplayAdd } = useContext(TableContext);

  const { t } = useTranslation();

  const { shownStores, sortedStoreColumns } = mall;
  const { columns, minimizedMalls, hiddenMalls } = tableSettings;

  const isMinimized = minimizedMalls?.includes(mall?.id);

  if (hiddenMalls.includes(mall.id) && !isEdit) return null;

  const disabledStores = getDisabledStores(shownStores, sortedStoreColumns, shownColumns);

  return (
    <div
      id={mall?.id}
      style={{
        width: 140 * columns.length + 160 + (shouldDisplayAdd ? 100 : 0),
        minWidth: 'calc(100vw - 120px)',
      }}
    >
      <div>
        {/* MALL HEADER */}
        <div className='flexColumn' style={{ width: 140 }}>
          <div
            className='TableMallComponent__mallName'
            onClick={() => toggleMallMinimized(tableUID, mall.id)}
          >
            {isEdit && (
              <ToggleableEye
                style={{ marginTop: -22 }}
                isDisabled={tableSettings.hiddenMalls.includes(mall.id)}
                onClick={() => toggleMallVisible(tableUID, mall.id)}
              />
            )}
            <div
              className={
                tableSettings.hiddenMalls.includes(mall.id)
                  ? 'hidden'
                  : 'mallName'
              }
            >
              {mall.name}
            </div>
            <Triangle
              className='triangle'
              style={{ transform: `rotate(${isMinimized ? 180 : 0}deg)` }}
            />
          </div>
          <div
            className={`TableMallComponent__hideableBlock ${
              isMinimized ? '' : 'open'
            }`}
          >
            <div className='flexColumn'></div>
          </div>
        </div>
        {/* MALL STORE DATA */}
        <div
          className={`TableMallComponent__hideableBlock ${
            isMinimized ? '' : 'open'
          }`}
        >
          {!isMinimized && (
            <div style={{ display: 'flex' }}>
              {/*shownStores.length === 0 && (
              <div className='TableMallComponent__noStores'>
                {t('no_stores')}
              </div>
            )*/}
              <div>
                {shownStores
                  .filter(store => !disabledStores.includes(store.id))
                  .map(store => (
                    <div key={store.id} className='TableMallComponent__storeName'>
                      {isEdit && (
                        <ToggleableEye
                          isDisabled={getIsStoreShown(
                            tableSettings,
                            null,
                            store.id,
                            mall.id,
                          )}
                          onClick={() =>
                            hiddenMalls.includes(mall.id)
                              ? undefined
                              : toggleStoreVisible(tableUID, store.id)
                          }
                        />
                      )}
                      <span
                        className={getIsStoreShown(
                          tableSettings,
                          null,
                          store.id,
                          mall.id,
                        )}
                      >
                        {store.name}
                      </span>
                    </div>
                  ))}
              </div>
              {shownStores.length > 0 && (
                <div className='TableMallComponent__storeRow'>
                  {shouldDisplayAdd && (
                    <div style={{ minWidth: 100, width: '100%' }} />
                  )}
                  {Object.keys(sortedStoreColumns).map((columnUID) => (
                    <div key={columnUID} className='TableMallComponent__storeRow__data'>
                      {Object.keys(sortedStoreColumns[columnUID])
                        .filter(storeId => !disabledStores.includes(storeId))
                        .map((storeId, index) => (
                          <div
                            key={storeId}
                            className={`TableMallComponent__cell ${getIsStoreShown(
                              tableSettings,
                              columnUID,
                              storeId,
                              mall.id,
                            )}`}
                            style={index === 0 ? { marginTop: 0 } : {}}
                          >
                            {getIsStoreDisabled({
                              shownColumns,
                              columnUID,
                              sortedStoreColumns,
                              storeId,
                            }) &&
                            !shownColumns.find((x) => x.columnUID === columnUID).isDiff
                              ? t('notActive')
                              : addSpaces(
                                  sortedStoreColumns[columnUID][storeId].cellData,
                                  { hideSecond: true },
                                )}
                            {shouldDisplayPercents(
                              shownColumns,
                              columnUID,
                              addSpaces(
                                sortedStoreColumns[columnUID][storeId].cellData,
                                { hideSecond: true },
                              ),
                            ) && ' %'}
                          </div>
                        ))}
                    </div>
                  ))}
                  {shouldDisplayAdd && (
                    <div style={{ minWidth: 100, width: '100%' }} />
                  )}
                  <div className='flexColumn' style={{ width: '100%' }} />
                </div>
              )}
            </div>
          )}
        </div>
        {/* DIFF */}
      </div>
    </div>
  );
}

export default TableMallComponent;
