// src/pages/AdminPanel/components/AdminEditFormSelect/index.jsx

import React from 'react';
import './styles.scss';
import { Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Triangle from '../../../../assets/icons/Triangle';

function AdminEditFormSelect({
  value,
  placeholder = 'Placeholder',
  options = [],
  onChange,
  name,
  disabled = false,
  onBlur,
  error = '',
  onDeleteClick,
}) {
  return (
    <div
      className='AdminEditFormSelect'
      style={{ opacity: disabled ? 0.4 : 1 }}
    >
      {name && <div className='AdminEditFormSelect__name'>{name}</div>}
      <div>
        <Select
          value={value === '' ? undefined : value}
          style={error ? { borderColor: '#d92828' } : { minWidth: 144 }}
          onChange={onChange}
          dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)', zIndex: 11000 }}
          placeholder={placeholder}
          options={options}
          className='AdminEditFormSelect__select'
          disabled={disabled}
          suffixIcon={
            onDeleteClick ? (
              <>
                <Triangle />
                <DeleteOutlined onClick={() => onDeleteClick(value)} />
              </>
            ) : (
              <Triangle />
            )
          }
          onBlur={onBlur}
        />
        {error && <div className='AdminEditFormSelect__error'>{error}</div>}
      </div>
    </div>
  );
}

export default AdminEditFormSelect;
