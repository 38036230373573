// src/pages/AdminPanel/components/AddNewButton/index.jsx

import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import './styles.scss';
import GrayButton from '../../../../components/GrayButton';

function AddNewButton({ text, onClick }) {
  return (
    <GrayButton
      className='AddNewButton'
      startIcon={<PlusOutlined />}
      onClick={onClick}
      text={text}
    />
  );
}

export default AddNewButton;
