// src/services/Saga/createNewUser/index.js

import { call, takeEvery } from 'redux-saga/effects';
import showNotification from '../../../utils/showNotification';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherCreateNewUser() {
  yield takeEvery(ActionTypes.SET_NEW_USER, workerCreateNewUser);
}
export function* workerCreateNewUser({ payload }) {
  const isArray = typeof payload.mall === 'string';
  const storeName = payload.store === 'Store' ? undefined : payload.store;
  const body = {
    email: payload.email,
    name: payload.name,
    surname: payload.surName,
    phoneNumber: payload.phone,
    roles: [payload.userRole],
    storeId: storeName,
    password: payload.password,
    mallIds: !isArray ? payload.mall : [payload.mall],
  };
  const deleteEmptyFields = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));

  const res = yield call(
    axiosInstance.post,
    `/admins/users`,
    deleteEmptyFields(body),
  );
  if (res?.response?.data?.userMessage) {
    showNotification({
      content: res?.response?.data?.userMessage,
      type: 'error',
    });
  }
  if (res.createdAt) {
    showNotification({ content: 'User Created', type: 'success' });
    payload.handler(false);
  }
}
