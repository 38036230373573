// src/components/IncomeTable/index.jsx

import React from 'react';
import TableController from './components/TableController';
import { IncomeTableProvider } from './IncomeTableContext';
import Tables from './components/Tables';

function IncomeTable() {
  return (
    <IncomeTableProvider>
      <div>
        {/* TableController component handles the control actions for the table */}
        <TableController />
        {/* Tables component displays the tables */}
        <Tables />
      </div>
    </IncomeTableProvider>
  );
}

export default IncomeTable;
