// src/components/IncomeTable/components/DateSelector/index.jsx

import dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { IncomeTableContext } from '../../IncomeTableContext';
import showNotification from '../../../../utils/showNotification';

dayjs.extend(weekOfYear);

function DateSelector({ createAt, column, tableUID, close }) {
  const { t } = useTranslation();
  const { addColumn, changeColumnDates } = useContext(IncomeTableContext);

  const [dates, setDates] = useState({
    from: dayjs().subtract(8, 'days'),
    to: dayjs().subtract(1, 'day'),
  });

  useEffect(() => {
    if (column?.from) {
      setDates({
        from: dayjs(column.from),
        to: dayjs(column.to),
      });
    }
  }, [column]);

  const presets = [
    {
      text: t('yesterday'),
      from: dayjs().subtract(1, 'day'),
      to: dayjs().subtract(1, 'day'),
      isYesterday: true,
    },
    {
      text: t('this_Month'),
      from: dayjs().startOf('month'),
      to: dayjs().subtract(1, 'day'),
      isThisMonth: true,
    },
    {
      text: t('last_Month'),
      from: dayjs().subtract(1, 'month').startOf('month'),
      to: dayjs().subtract(1, 'month').endOf('month'),
      isLastMonth: true,
    },
    {
      text: t('accumulated'),
      from: dayjs().startOf('year'),
      to: dayjs().subtract(1, 'day'),
      isAccumulated: true,
    },
  ];

  const onSave = (values) => {
    if (values.from.isAfter(values.to)) {
      showNotification({
        content: 'Wrong date format. "To" must be after "From"',
        type: 'error',
      });
      return;
    }
    close();
    if (createAt) {
      addColumn(tableUID, values, createAt === 'end');
    } else {
      changeColumnDates(tableUID, column.columnUID, values);
    }
  };

  const onDateChange = (field, type, value) => {
    if (type === 'year' && value < 10) {
      showNotification({
        content: 'Year must be after 2010!',
        type: 'error',
      });
      return;
    }
    setDates((prev) => ({
      ...prev,
      [field]: prev[field].set(
        type,
        type === 'year' ? '20' + value : type === 'month' ? value - 1 : value,
      ),
    }));
  };

  const onWeekChange = (weekNumber) => {
    setDates({
      from: dates.from.week(weekNumber).startOf('week'),
      to: dates.from.week(weekNumber).endOf('week'),
    });
  };

  const isWeek =
    dates.from.startOf('day').isSame(dates.from.startOf('week')) &&
    dates.to.endOf('day').isSame(dates.to.endOf('week')) &&
    dates.from.diff(dates.to, 'week') === 0;

  return (
    <div className='DateSelector'>
      <div className='DateSelector__presets'>
        {presets.map((preset) => (
          <div key={preset.text} onClick={() => onSave(preset)}>
            {preset.text}
          </div>
        ))}
      </div>
      <div className='DateSelector__wrapper'>
        <div className='DateSelector__dates'>
          <div className='DateSelector__border'>
            <NumberInput
              value={dates.from.get('date')}
              onChange={(value) => onDateChange('from', 'date', value)}
            />
            /
            <NumberInput
              value={dates.from.get('month') + 1}
              onChange={(value) => onDateChange('from', 'month', value)}
            />
            /
            <NumberInput
              value={dates.from.get('year').toString().slice(2, 4)}
              onChange={(value) => onDateChange('from', 'year', value)}
            />
          </div>
          <div className='DateSelector__line' />
          <div className='DateSelector__border'>
            <NumberInput
              value={dates.to.get('date')}
              onChange={(value) => onDateChange('to', 'date', value)}
            />
            /
            <NumberInput
              value={dates.to.get('month') + 1}
              onChange={(value) => onDateChange('to', 'month', value)}
            />
            /
            <NumberInput
              value={dates.to.get('year').toString().slice(2, 4)}
              onChange={(value) => onDateChange('to', 'year', value)}
            />
          </div>
        </div>
        <div className='DateSelector__border'>
          <span>{t('week')}</span>
          <NumberInput
            value={isWeek ? dates.from.week() : ''}
            onChange={onWeekChange}
          />
        </div>
      </div>
      <div className='DateSelector__bottom'>
        <Button
          onClick={() => onSave(dates)}
          style={{ color: 'white', border: '1px solid grey' }}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
}

const NumberInput = ({ value = '', onChange }) => {
  const [innerValue, setInnerValue] = useState('');
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const debouncedChangeHandler = useCallback(debounce(onChange, 600), []);

  const onInnerChange = (e) => {
    let tvalue = e.target.value;
    tvalue = tvalue.replace(/[^0-9]+/g, '');
    setInnerValue(tvalue);
    debouncedChangeHandler(tvalue || value);
  };

  return (
    <div className='NumberInput'>
      <div onClick={() => onChange(Number(value) + 1, 'plus')}>
        <UpOutlined />
      </div>
      <input
        value={innerValue}
        onChange={onInnerChange}
        onBlur={() => {
          if (innerValue === '') setInnerValue(value);
        }}
      />
      <div onClick={() => onChange(value - 1 >= 1 ? value - 1 : 1, 'minus')}>
        <DownOutlined />
      </div>
    </div>
  );
};

export default DateSelector;
