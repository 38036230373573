// src/components/LeftMenu/Mobile/index.jsx

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import Admin from '../../../assets/icons/Admin';
import Dashboard from '../../../assets/icons/Dashboard';
import Income from '../../../assets/icons/Income';
import List from '../../../assets/icons/List';
import { ROLES } from '../../../pages/AdminPanel/utils/formatForTables';
import { isMobileDevice } from '../../../utils/deviceDetection';

import './style.scss';

export const LeftSideMenuMobile = ({ selected }) => {
  const user = useSelector((state) => state.loginReducer.user);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dashboardColor, setDashboardColor] = useState('#FAFAFA');
  const [listColor, setListColor] = useState('#FAFAFA');
  const [incomeColor, setIncomeColor] = useState('#FAFAFA');
  const [adminColor, setAdminColor] = useState('#FAFAFA');

  const onTitlePress = (key) => {
    navigate(key);
  };

  if (isMobileDevice()) {
    return <LeftSideMenuMobile selected={selected} />;
  }

  return (
    <div className='MobileNavMenu'>
      <div className='mobileIconContainer'>
        <Tooltip title={t('dashboard')} placement='right'>
          <button
            className='navButton'
            onClick={() => onTitlePress('/dashboard')}
            onMouseEnter={() => setDashboardColor('rgba(218, 253, 0, 1)')}
            onMouseLeave={() => setDashboardColor('#FAFAFA')}
          >
            <Dashboard selected={selected === 'HOME'} color={dashboardColor} />
          </button>
        </Tooltip>
        {(user?.roles[0] === ROLES.ADMIN ||
          user?.roles[0] === ROLES.MALL_MANAGER ||
          user?.roles[0] === ROLES.MALL_OWNER) && (
          <Tooltip title={t('reporting_List')} placement='right'>
            <button
              className='navButton'
              onClick={() => onTitlePress('/list')}
              onMouseEnter={() => setListColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setListColor('#FAFAFA')}
            >
              <List selected={selected === 'LIST'} color={listColor} />
            </button>
          </Tooltip>
        )}
        {user?.roles[0] === ROLES.STORE_MANAGER && (
          <Tooltip title={t('report_Income')} placement='right'>
            <button
              className='navButton'
              onClick={() => onTitlePress('/income')}
              onMouseEnter={() => setIncomeColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setIncomeColor('#FAFAFA')}
            >
              <Income selected={selected === 'INCOME'} color={incomeColor} />
            </button>
          </Tooltip>
        )}
        {user?.roles[0] === ROLES.ADMIN && (
          <Tooltip title={t('admin_Panel')} placement='right'>
            <button
              className='navButton'
              onClick={() => onTitlePress('/admin/malls')}
              onMouseEnter={() => setAdminColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setAdminColor('#FAFAFA')}
            >
              <Admin selected={selected === 'ADMIN'} color={adminColor} />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
