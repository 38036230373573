// src/assets/icons/FullScreen.jsx

import * as React from 'react';
const FullScreen = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke='#797979'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M16.875 12.5v4.375H12.5m3.977-.4-4.602-4.6M3.125 7.5V3.125H7.5m-3.977.4 4.602 4.6m4.375-5h4.375V7.5m-.4-3.977-4.6 4.602M7.5 16.875H3.125V12.5m.4 3.977 4.6-4.602'
    />
  </svg>
);
export default FullScreen;
