// src/components/IncomeTable/IncomeTableContext.jsx

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';

import { SORT, defaultTables, generateDefaultTable, standartColumnProperties } from './const';
import generateUID from './utils/generateUID';
import changeSavedTables from './utils/changeSavedTables';
import { getTotalIncomeAmount } from '../../services/Saga/getTotalIncomeAmount/actions';
import fillTable from './utils/fillTables';
import { formatTablesSettingsToIncludeSortedMalls } from './utils/sortTable';
import axiosInstance from '../../services/Axios/axiosInstance';
import { clearTotalIncomeAmount } from '../../services/Redux/totalIncomeCardsReducer/action';

export const IncomeTableContext = createContext({
  columns: null,
  tablesSettings: null,
  isEditing: false,
  selectedTable: null,
  shouldShowPercentageDiff: false,
  shouldShowNumberDiff: false,
  lastVisibleTable: null,
  animation: null,
  setAnimation: () => {},
  setLastVisibleTable: () => {},
  toggleEditing: () => {},
  selectTable: () => {},
  changeColumnType: () => {},
  toggleColumnTax: () => {},
  toggleStoreVisible: () => {},
  toggleMallVisible: () => {},
  removeColumn: () => {},
  addColumn: () => {},
  addTable: () => {},
  toggleSort: () => {},
  deleteTable: () => {},
  changeColumnDates: () => {},
  toggleColumnVisibility: () => {},
  changeColumnSort: () => {},
  updateFilters: () => {},
  moveTable: () => {},
  toggleMallMinimized: () => {},
});

export const IncomeTableProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loginReducer.user);
  const totalIncomeAmount = useSelector((state) => state.totalIncomeCardReducer.totalIncomeAmount);

  const [tablesSettings, setTablesSettings] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [lastVisibleTable, setLastVisibleTable] = useState(null);
  const [animation, setAnimation] = useState(null);

  const changeTables = useCallback((newTables) => {
    setTablesSettings(newTables);
    changeSavedTables(newTables);
  }, []);

  const changeTableSettings = useCallback((tableUID, newTable) => {
    const copiedTables = cloneDeep(tablesSettings);
    copiedTables[tableUID] = newTable;
    changeTables(copiedTables);
  }, [tablesSettings, changeTables]);

  useEffect(() => {
    const getTable = async () => {
      if (!user?.id) return;

      const userSettings = await axiosInstance.get(`/users/info`).then((res) => res.data.settings).catch(() => user.settings);
      let savedIncomeTablesSettings = {};

      try {
        savedIncomeTablesSettings = JSON.parse(userSettings);
      } catch {
        savedIncomeTablesSettings = userSettings || {};
      }

      setLastVisibleTable(Object.keys(savedIncomeTablesSettings)[0] || null);

      Object.keys(savedIncomeTablesSettings).forEach((tableUID) => {
        savedIncomeTablesSettings[tableUID].columns.forEach((col) => {
          if (col.isYesterday) {
            col.from = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            col.to = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
          }

          if (col.isThisMonth) {
            col.from = dayjs().startOf('month').format('YYYY-MM-DD');
            col.to = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
          }

          if (col.isLastMonth) {
            col.from = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            col.to = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
          }

          if (col.isAccumulated) {
            col.from = dayjs().startOf('year').format('YYYY-MM-DD');
            col.to = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
          }
        });
      });

      let newTables = Object.keys(savedIncomeTablesSettings).length > 2 ? savedIncomeTablesSettings : defaultTables;

      newTables = Object.fromEntries(
        Object.entries(newTables).filter(([uid, table]) => table.columns.some((x) => x.from))
      );

      changeTables(newTables);
      Object.keys(newTables).forEach((tableUID) =>
        dispatch(
          getTotalIncomeAmount({
            columns: newTables[tableUID].columns.filter((col) => col.from).map((col) => ({
              from: col.from,
              to: col.to,
            })),
            filters: newTables[tableUID].filters,
            tableUID,
          })
        )
      );
    };

    getTable();
  }, [user?.id, changeTables, dispatch]);

  useEffect(() => {
    if (!tablesSettings) return;
    const filledTables = fillTable(tablesSettings, totalIncomeAmount);
    setTablesSettings(filledTables);
    setTimeout(() => setAnimation(null), 120);
  }, [totalIncomeAmount]);

  useEffect(() => {
    dispatch(clearTotalIncomeAmount());
  }, [dispatch]);

  const addColumn = useCallback((tableUID, dates, createAtEnd = true) => {
    const {
      from,
      to,
      isAccumulated = false,
      isLastMonth = false,
      isThisMonth = false,
      isYesterday = false,
    } = dates;

    const clonedTables = cloneDeep(tablesSettings);
    const newColumn = {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      isAccumulated,
      isLastMonth,
      isThisMonth,
      isYesterday,
      columnUID: generateUID(),
      ...standartColumnProperties,
    };
    const columns = cloneDeep(clonedTables[tableUID].columns);
    if (createAtEnd) {
      columns.splice(-2, 0, newColumn);
    } else columns.unshift(newColumn);
    clonedTables[tableUID] = { ...clonedTables[tableUID], columns };

    dispatch(
      getTotalIncomeAmount({
        columns: clonedTables[tableUID].columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: clonedTables[tableUID].filters,
        tableUID,
      })
    );
    delete clonedTables[tableUID]?.malls;
    changeTables(clonedTables);
  }, [tablesSettings, dispatch, changeTables]);

  const updateFilters = useCallback((tableUID, filters) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.filters = filters;
    dispatch(
      getTotalIncomeAmount({
        columns: changedTablesSettings.columns
          .filter((col) => col.from)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: changedTablesSettings.filters,
        tableUID,
      })
    );
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, dispatch, changeTableSettings]);

  const changeColumnDates = useCallback((tableUID, columnUID, dates) => {
    const {
      from,
      to,
      isAccumulated = false,
      isLastMonth = false,
      isThisMonth = false,
      isYesterday = false,
    } = dates;

    const clonedTables = cloneDeep(tablesSettings);
    const changedColumnsSettings = clonedTables[tableUID].columns.find((col) => col.columnUID === columnUID);
    changedColumnsSettings.from = from.format('YYYY-MM-DD');
    changedColumnsSettings.to = to.format('YYYY-MM-DD');
    changedColumnsSettings.isAccumulated = isAccumulated;
    changedColumnsSettings.isLastMonth = isLastMonth;
    changedColumnsSettings.isThisMonth = isThisMonth;
    changedColumnsSettings.isYesterday = isYesterday;

    dispatch(
      getTotalIncomeAmount({
        columns: clonedTables[tableUID].columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        tableUID,
      })
    );
    delete clonedTables[tableUID]?.malls;
    changeTables(clonedTables);
  }, [tablesSettings, dispatch, changeTables]);

  const removeColumn = useCallback((tableUID, columnUID) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.columns = changedTablesSettings.columns.filter((col) => col.columnUID !== columnUID);
    dispatch(
      getTotalIncomeAmount({
        columns: changedTablesSettings.columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: changedTablesSettings.filters,
        tableUID,
      })
    );
    delete changedTablesSettings?.malls;
    changeTables(copiedTablesSettings);
  }, [tablesSettings, dispatch, changeTables]);

  const toggleColumnVisibility = useCallback((tableUID, columnUID) => {
    const clonedTables = cloneDeep(tablesSettings);
    const changedColumnsSettings = clonedTables[tableUID].columns.find((col) => col.columnUID === columnUID);
    changedColumnsSettings.isVisible = !changedColumnsSettings.isVisible;

    changeTableSettings(tableUID, clonedTables[tableUID]);
  }, [tablesSettings, changeTableSettings]);

  const moveTable = useCallback((direction) => {
    const clonedTables = cloneDeep(tablesSettings);
    const tableEntries = Object.entries(clonedTables);
    let selectedTableIndex = -1;
    const selectedTableObj = cloneDeep(
      tableEntries.find(([tableUID], index) => {
        const t = tableUID === selectedTable;
        if (t) selectedTableIndex = index;
        return t;
      })
    );
    const newIndex = selectedTableIndex + (direction === 'down' ? 1 : -1);
    tableEntries[selectedTableIndex] = cloneDeep(tableEntries[newIndex]);
    tableEntries[newIndex] = selectedTableObj;
    const newTables = Object.fromEntries(tableEntries);

    changeTables(newTables);
  }, [tablesSettings, selectedTable, changeTables]);

  const addTable = useCallback(() => {
    const tableUID = generateUID();
    const newTable = generateDefaultTable();
    changeTableSettings(tableUID, newTable);
    dispatch(
      getTotalIncomeAmount({
        columns: newTable.columns
          .filter((x) => !x.isDiff)
          .map((col) => ({
            from: col.from,
            to: col.to,
          })),
        filters: newTable.filters,
        tableUID,
      })
    );
  }, [dispatch, changeTableSettings]);

  const deleteTable = useCallback(() => {
    setSelectedTable(null);
    const copiedTables = cloneDeep(tablesSettings);
    delete copiedTables[selectedTable];
    setTablesSettings(copiedTables);
    changeSavedTables(copiedTables);
  }, [tablesSettings, selectedTable]);

  const toggleStoreVisible = useCallback((tableUID, storeId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.hiddenStores = changedTablesSettings.hiddenStores.includes(storeId)
      ? changedTablesSettings.hiddenStores.filter((id) => id !== storeId)
      : [...changedTablesSettings.hiddenStores, storeId];
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const toggleMallVisible = useCallback((tableUID, mallId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.hiddenMalls = changedTablesSettings.hiddenMalls.includes(mallId)
      ? changedTablesSettings.hiddenMalls.filter((id) => id !== mallId)
      : [...changedTablesSettings.hiddenMalls, mallId];
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const toggleMallMinimized = useCallback((tableUID, mallId) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    if (changedTablesSettings.minimizedMalls) {
      changedTablesSettings.minimizedMalls = changedTablesSettings.minimizedMalls.includes(mallId)
        ? changedTablesSettings.minimizedMalls.filter((id) => id !== mallId)
        : [...changedTablesSettings.minimizedMalls, mallId];
    } else {
      changedTablesSettings.minimizedMalls = [mallId];
    }
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const toggleEditing = useCallback(() => setIsEditing((prev) => !prev), []);
  
  const selectTable = useCallback((tableUID) => {
    const isAlreadySelected = selectedTable === tableUID;
    setSelectedTable(isAlreadySelected ? null : tableUID);
    if (isAlreadySelected) setIsEditing(false);
  }, [selectedTable]);

  const toggleColumnTax = useCallback((tableUID, columnUID) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    const changedColumnsSettings = changedTablesSettings.columns.find((col) => col.columnUID === columnUID);
    changedColumnsSettings.tax = changedColumnsSettings.tax === 'included' ? 'excluded' : 'included';
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const changeColumnSort = useCallback((tableUID, newColumns) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    changedTablesSettings.columns = newColumns;
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const changeColumnType = useCallback((tableUID, columnUID, type) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];
    const changedColumnsSettings = changedTablesSettings.columns.find((col) => col.columnUID === columnUID);
    changedColumnsSettings.type = type;
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  const toggleSort = useCallback((tableUID, columnUID) => {
    const copiedTablesSettings = cloneDeep(tablesSettings);
    const changedTablesSettings = copiedTablesSettings[tableUID];

    if (columnUID !== changedTablesSettings.sortedByColumnUID) {
      changedTablesSettings.sort = SORT[1];
      changedTablesSettings.sortedByColumnUID = columnUID;
    } else {
      const sortIndex = SORT.findIndex((x) => x === changedTablesSettings.sort);
      changedTablesSettings.sort = SORT[sortIndex + 1 >= SORT.length ? 0 : sortIndex + 1];
      changedTablesSettings.sortedByColumnUID = columnUID;
    }
    changeTableSettings(tableUID, changedTablesSettings);
  }, [tablesSettings, changeTableSettings]);

  return (
    <IncomeTableContext.Provider
      value={{
        tablesSettings: tablesSettings
          ? formatTablesSettingsToIncludeSortedMalls(tablesSettings, (tableUID) => isEditing && tableUID === selectedTable)
          : {},
        isEditing,
        selectedTable,
        lastVisibleTable,
        animation,
        setAnimation,
        setLastVisibleTable,
        toggleEditing,
        selectTable,
        changeColumnType,
        toggleColumnTax,
        toggleStoreVisible,
        toggleMallVisible,
        removeColumn,
        addColumn,
        addTable,
        toggleSort,
        deleteTable,
        changeColumnDates,
        toggleColumnVisibility,
        changeColumnSort,
        updateFilters,
        moveTable,
        toggleMallMinimized,
      }}
    >
      {children}
    </IncomeTableContext.Provider>
  );
};
