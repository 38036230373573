// src/pages/AdminPanel/pages/mall/MallInfo/EditForm.jsx

import React, { useState } from 'react';
import GrayButton from '../../../../../components/GrayButton';
import { useTranslation } from 'react-i18next';
import AdminEditFormInput from '../../../components/AdminEditFormInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ROLES, roles } from '../../../utils/formatForTables';
import AdminApi from '../../../../../services/Axios/AdminApi';
import AdminEditFormMultipleSelect from '../../../components/AdminEditFormMultipleSelect';

function EditForm({ mall, onSave, onCancel }) {
  const { t } = useTranslation();
  const [mallOwners, setMallOwners] = useState([]);
  const [mallManagers, setMallManagers] = useState([]);

  const initialValue = {
    name: mall.name,
    mallOwners: mall.mallOwners.map((owner) => owner.id),
    mallManagers: mall.mallMangers.map((manager) => manager.id),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .trim('validation.trimError')
      .matches(/^[a-zA-ZæøåÆØÅ0-9 ]+$/, t('validation.shouldBeText'))
      .required(t('validation.required')),
  });

  const onInnerSubmit = (values) => {
    const formattedValues = {
      name: values.name,
      mallOwnerIds: values.mallOwners || undefined,
      mallManagerIds: values.mallManagers || undefined,
    };
    return onSave(formattedValues);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: initialValue,
    onSubmit: onInnerSubmit,
    validationSchema,
  });

  React.useEffect(() => {
    AdminApi.getUsers({
      role: ROLES.MALL_MANAGER,
      limit: 149,
    }).then((res) => {
      setMallManagers(res.data);
    });
    AdminApi.getUsers({
      role: ROLES.MALL_OWNER,
      limit: 149,
    }).then((res) => {
      setMallOwners(res.data);
    });
  }, []);

  return (
    <div className='EditForm'>
      <AdminEditFormInput
        name={t('adminTable.mallName')}
        placeholder={t('adminTable.mallName')}
        value={values.name}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        error={touched.name && errors.name}
      />
      <AdminEditFormMultipleSelect
        name={t('adminTable.mallOwners')}
        placeholder={t('adminTable.mallOwners')}
        values={values.mallOwners}
        options={mallOwners.map((mall) => ({
          value: mall.id,
          label: mall.name,
        }))}
        onChange={(val) => setFieldValue('mallOwners', val)}
        onBlur={handleBlur('mallOwners')}
        error={touched.mallOwners && errors.mallOwners}
      />
      <AdminEditFormMultipleSelect
        name={t('adminTable.mallManagers')}
        placeholder={t('adminTable.mallManagers')}
        values={values.mallManagers}
        options={mallManagers.map((mall) => ({
          value: mall.id,
          label: mall.name,
        }))}
        onChange={(val) => setFieldValue('mallManagers', val)}
        onBlur={handleBlur('mallManagers')}
        error={touched.mallManagers && errors.mallManagers}
      />
      <div className='EditForm__buttons'>
        <GrayButton onClick={onCancel}>{t('cancel')}</GrayButton>
        <GrayButton onClick={handleSubmit} loading={isSubmitting}>
          {t('save')}
        </GrayButton>
      </div>
    </div>
  );
}

export default EditForm;
