// src/components/Header/Header.jsx

import { Select, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LanguageIcon from '../../assets/icons/LanguageIcon';
import LogOut from '../../assets/icons/logOut';
import { logOut, setUserLanguage } from '../../services/Saga/loginSaga/actions';
import { Colors } from '../../utils/colors';
import './style.scss';
import FullScreen from '../../assets/icons/FullScreen';
import ArrowNext from '../../assets/icons/ArrowNext';
import { useNavigate } from 'react-router';

export const Header = ({ title, goBack }) => {
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState('#797979');
  const [isHovering, setIsHovering] = useState(false);

  const onPressLogOut = () => {
    dispatch(logOut(navigate));
  };
  const { t, i18n } = useTranslation();
  const languageOptions = [
    {
      label: <span className='headerSelectText'>Eng</span>,
      value: 'en',
    },
    {
      label: <span className='headerSelectText'>Nor</span>,
      value: 'nor',
    },
  ];

  return (
    <div
      style={{
        backgroundColor: Colors.mainBlack,
      }}
      className='mainHeaderContainer'
    >
      <div className='headerWrapper'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              color: Colors.mainYellow,
            }}
            className='logo'
          >
            Omsetningen
          </span>

          {!title && goBack && (
            <span
              onClick={goBack.onClick}
              style={{
                cursor: 'pointer',
                marginTop: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowNext
                style={{
                  transform: 'rotate(180deg)',
                  marginLeft: 24,
                  width: 10,
                  height: 16,
                  stroke: 'white',
                }}
              />
              <span className='headerText' style={{ paddingLeft: 12 }}>
                {goBack.title}
              </span>
            </span>
          )}
          {title && <span className='headerText'>{title}</span>}
        </div>
        <div className='languageContainer'>
          <Tooltip title={t('open_fullscreen')}>
            <div
              className='fullScreen'
              onClick={() => {
                if (document.fullscreenElement) document.exitFullscreen();
                else document.documentElement.requestFullscreen();
              }}
            >
              <FullScreen />
            </div>
          </Tooltip>
          <Tooltip
            title={t('choose_language')}
            open={isHovering && !isLanguageOpen}
          >
            <div
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <button className='headerBorderlessButton'>
                <Select
                  className='headerSelectLanguage hideTextSelect'
                  dropdownStyle={{
                    backgroundColor: Colors.greyBlack,
                  }}
                  style={{ width: 54 }}
                  variant={false}
                  suffixIcon={
                    <LanguageIcon
                      color={isHovering ? 'rgba(218, 253, 0, 1)' : '#797979'}
                      width={18}
                      height={18}
                    />
                  }
                  options={languageOptions}
                  onChange={(value) => {
                    i18n.changeLanguage(value);
                    dispatch(setUserLanguage(value));
                  }}
                  onDropdownVisibleChange={() =>
                    setIsLanguageOpen(!isLanguageOpen)
                  }
                />
              </button>
            </div>
          </Tooltip>
          <Tooltip title={t('log_Out')}>
            <button
              className='logOutContainer'
              onClick={onPressLogOut}
              onMouseEnter={() => setColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setColor('#797979')}
            >
              <LogOut color={color} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
