// src/pages/AdminPanel/components/modals/AddUser/useAddUser.jsx

import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminApi from '../../../../../services/Axios/AdminApi';
import * as Yup from 'yup';
import showNotification from '../../../../../utils/showNotification';

function useAddUser({ onUserCreated, store, mall, close }) {
  const { t } = useTranslation();
  const [malls, setMalls] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    AdminApi.getMalls().then((res) => {
      setMalls(res.data);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .trim('validation.trimError')
      .matches(
        /^[a-zA-ZæøåÆØÅ _]*[a-zA-ZæøåÆØÅ]+$/,
        t('validation.shouldBeText'),
      )
      .required(t('validation.required')),
    surname: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .trim('validation.trimError')
      .matches(
        /^[a-zA-ZæøåÆØÅ _]*[a-zA-ZæøåÆØÅ]+$/,
        t('validation.shouldBeText'),
      )
      .required(t('validation.required')),
    phoneNumber: Yup.string()
      .test('len', t('validation.invalidPhone'), (val) => {
        return val.replaceAll('_', '').replaceAll(' ', '').length >= 11;
      })
      .required(t('validation.required')),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
    role: Yup.string().required(t('validation.required')),
    password: Yup.string()
      .min(8, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .required(t('validation.required')),
  });

  const initialValues = {
    name: '',
    surname: '',
    phoneNumber: '',
    email: '',
    password: '',
    role: '',
    mall: '',
    store: '',
  };

  const onSubmit = (values, { resetForm }) => {
    AdminApi.createUser({
      email: values.email,
      name: values.name,
      surname: values.surname,
      phoneNumber: values.phoneNumber.replaceAll('_', '').replaceAll(' ', ''),
      roles: [values.role],
      storeId: values.store || store?.id || undefined,
      password: values.password,
      mallIds: mallId ? [mallId] : undefined,
    })
      .then((res) => {
        onUserCreated(res);
        resetForm();
        close();
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldTouched,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
  });

  const mallId = values.mall || mall?.id || store?.mallId;

  React.useEffect(() => {
    setFieldValue('mall', mall?.id);
  }, [mall?.id]);

  React.useEffect(() => {
    if (mallId) {
      AdminApi.getStores({ mallId, available: true }).then((res) => {
        setStores(res.data);
      });
    }
  }, [mallId]);

  return {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    t,
    malls,
    stores,
    touched,
    handleBlur,
    setFieldTouched,
  };
}

export default useAddUser;
