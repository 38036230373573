// src/pages/AdminPanel/utils/formatForTables.js

import dayjs from 'dayjs';
import i18next from 'i18next';

// Define roles constants
export const ROLES = {
  ADMIN: 'ADMIN',
  MALL_MANAGER: 'MALL_MANAGER',
  MALL_OWNER: 'MALL_OWNER',
  STORE_MANAGER: 'STORE_MANAGER',
};

// Human-readable role names
export const roles = {
  [ROLES.ADMIN]: 'Admin',
  [ROLES.MALL_MANAGER]: 'Mall manager',
  [ROLES.MALL_OWNER]: 'Mall owner',
  [ROLES.STORE_MANAGER]: 'Store manager',
};

// Get translated roles using i18next
export const getTranslatedRoles = () => ({
  [ROLES.ADMIN]: i18next.t(`adminTable.${ROLES.ADMIN.toLowerCase()}`),
  [ROLES.MALL_MANAGER]: i18next.t(`adminTable.${ROLES.MALL_MANAGER.toLowerCase()}`),
  [ROLES.MALL_OWNER]: i18next.t(`adminTable.${ROLES.MALL_OWNER.toLowerCase()}`),
  [ROLES.STORE_MANAGER]: i18next.t(`adminTable.${ROLES.STORE_MANAGER.toLowerCase()}`),
});

// Format users based on search text and page
export const formatUsers = (users = [], searchText = '', page) => {
  const search = searchText.toLowerCase();
  return users
    .filter((user) => {
      const name = (user.name + ' ' + user.surname).toLowerCase();
      const phone = user.phoneNumber.toLowerCase();
      const email = user.email.toLowerCase();
      const role = user.roles.map((role) => roles[role]).join(', ').toLowerCase();
      return [name, phone, email, role].some((str) => str.includes(search));
    })
    .map((user, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: user.id,
      name: `${user.name} ${user.surname}`,
      phone: user.phoneNumber || '-',
      email: user.email || '-',
      role: user.roles[0]?.toLowerCase() || '',
    }));
};

// Format stores based on search text and page
export const formatStores = (stores, searchText, page) => {
  const search = searchText.toLowerCase();
  return stores
    .filter((store) => {
      const name = store.name.toLowerCase();
      const mall = store?.mall?.name.toLowerCase();
      const managers = `${store?.storeManager?.name || ''} ${store?.storeManager?.surname || ''}`.toLowerCase();
      return [name, mall, managers].some((str) => str.includes(search));
    })
    .map((store, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: store.id,
      name: store.name,
      mall: store?.mall?.name || '-',
      storeManager: store?.storeManager ? `${store.storeManager.name} ${store.storeManager.surname}` : '',
      storeManagerId: store?.storeManager?.id,
      tax: store.tax,
      closed: (store.endDate ? dayjs(store.endDate).add(1, 'day').isBefore(dayjs()) : false) ||
              (store.startDate ? dayjs(store.startDate).isAfter(dayjs()) : false),
      users: store.user,
    }));
};

// Format malls based on search text and page
export const formatMalls = (malls, searchText, page = 1) => {
  const search = searchText.toLowerCase();
  return malls
    .filter((mall) => {
      const name = mall.name.toLowerCase();
      const managers = (mall.mallMangers.map(({ name, surname }) => `${name} ${surname}`).join(', ') || '-').toLowerCase();
      const owners = (mall.mallOwners.map(({ name, surname }) => `${name} ${surname}`).join(', ') || '-').toLowerCase();
      return [name, managers, owners].some((str) => str.includes(search));
    })
    .map((mall, index) => ({
      index: (page - 1) * 10 + index + 1,
      id: mall.id,
      name: mall.name,
      managers: mall.mallMangers.map(({ name, surname }) => `${name} ${surname}`).join(', ') || '-',
      owners: mall.mallOwners.map(({ name, surname }) => `${name} ${surname}`).join(', ') || '-',
      stores: mall.totalStores,
      users: mall.totalUsers,
    }));
};
