// actions.js

import { ActionTypes } from './actionTypes';

const createAction = (type, payload) => ({
  type,
  payload,
});

export const setReportingList = (payload) => {
  console.log('Action: SET_REPORTING_LIST', payload);
  return createAction(ActionTypes.SET_REPORTING_LIST, payload);
};

export const setFilters = (payload) => {
  console.log('Action: SET_FILTERS', payload);
  return createAction(ActionTypes.SET_FILTERS, payload);
};