// src/services/Redux/totalIncomeCardsReducer/actionTypes.js

// Action types for income and card actions
export const ActionTypes = {
  SET_TOTAL_INCOME_CARD: 'SET_TOTAL_INCOME_CARD',
  SET_TOP_STORE_CARD: 'SET_TOP_STORE_CARD',
  SET_TOP_MALL_CARD: 'SET_TOP_MALL_CARD',
  SET_TOTAL_INCOME_AMOUNT: 'SET_TOTAL_INCOME_AMOUNT',
  CLEAR_TOTAL_INCOME_AMOUNT: 'CLEAR_TOTAL_INCOME_AMOUNT',
  
  SET_CONSOLIDATED_INCOME: 'SET_CONSOLIDATED_INCOME',
  SET_TOP_PERCENTAGE_GROWTH: 'SET_TOP_PERCENTAGE_GROWTH',
  SET_CONSOLIDATED_FOOD: 'SET_CONSOLIDATED_FOOD',
  SET_TAX: 'SET_TAX',
};
