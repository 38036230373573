// src/services/Saga/totalIncomes/actionsTypes.js

export const ActionTypes = {
  GET_TOTAL_INCOME: 'GET_TOTAL_INCOME',
  GET_TOP_MALL_INCOME: 'GET_TOP_MALL_INCOME',
  GET_TOP_STORE_INCOME: 'GET_TOP_STORE_INCOME',

  GET_CONSOLIDATED_INCOME: 'GET_CONSOLIDATED_INCOME',
  GET_TOP_PERCENTAGE_GROWTH: 'GET_TOP_PERCENTAGE_GROWTH',
  GET_CONSOLIDATED_FOOD: 'GET_CONSOLIDATED_FOOD',
};
