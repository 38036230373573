import i18next from 'i18next';

export const isProd = !document.location.host.includes('localhost');

export const MAIN_URL = isProd
  ? 'https://dev.omsetningen.no'
  : 'http://localhost:8000';


export const months = () => [
  { name: i18next.t('jan'), number: '01' },
  { name: i18next.t('feb'), number: '02' },
  { name: i18next.t('mar'), number: '03' },
  { name: i18next.t('apr'), number: '04' },
  { name: i18next.t('may'), number: '05' },
  { name: i18next.t('jun'), number: '06' },
  { name: i18next.t('jul'), number: '07' },
  { name: i18next.t('aug'), number: '08' },
  { name: i18next.t('sep'), number: '09' },
  { name: i18next.t('oct'), number: '10' },
  { name: i18next.t('nov'), number: '11' },
  { name: i18next.t('dec'), number: '12' },
];

export const NOTIFICATION_CSS_CLASSES = {
  success: 'success-notification',
  error: 'error-notification',
  warning: 'warning-notification',
  info: 'info-notification',
};

export const TAX = {
  EXCLUDED: 'excluded',
  INCLUDED: 'included',
};
