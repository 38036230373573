// src/components/IncomeTable/components/Table/index.jsx

import React, { useContext } from 'react';
import TableMallComponent from '../TableMallComponent';
import TableHeader from '../TableHeader';
import TableTotal from '../TableTotal';
import { TableContext, TableProvider } from './TableContext';
import { IncomeTableContext } from '../../IncomeTableContext';

function Table({ tableUID }) {
  const { tablesSettings } = useContext(IncomeTableContext);

  // Render the table only if the table settings for the given tableUID exist
  if (tablesSettings[tableUID]) {
    return (
      <TableProvider tableUID={tableUID}>
        <div>
          <TableHeader />
          <TableContext.Consumer>
            {({ malls }) => (
              <>
                {malls.map((mall) => (
                  <TableMallComponent mall={mall} key={mall.id} />
                ))}
              </>
            )}
          </TableContext.Consumer>
          <TableTotal />
        </div>
      </TableProvider>
    );
  }

  // Return an empty fragment if the table settings for the given tableUID do not exist
  return <></>;
}

export default Table;
