// src/pages/AdminPanel/components/AdminTablesController/index.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import './styles.scss';

function AdminTablesController() {
  const { t } = useTranslation();

  const location = useLocation();

  const options = [
    { text: t('malls'), link: '/admin/malls' },
    { text: t('stores'), link: '/admin/stores' },
    { text: t('users'), link: '/admin/users' },
  ];

  return (
    <div className='AdminTablesController'>
      {options.map(({ text, link }) => (
        <Link
          key={text}
          to={link}
          className={link === location.pathname ? 'selected' : ''}
        >
          {text}
        </Link>
      ))}
    </div>
  );
}

export default AdminTablesController;
