// src/components/HomePageCards/index.jsx

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TopCard from '../../pages/Home/components/TopCard';
import './styles.scss';
import {
  getConsolidatedFood,
  getConsolidatedIncome,
  getTopPercentageGrowth,
} from '../../services/Saga/totalIncomes/actions';
import { TOP_CARD_TYPES } from '../../services/Redux/totalIncomeCardsReducer';
import { setTax } from '../../services/Redux/totalIncomeCardsReducer/action';
import { TAX } from '../../utils/constants';

function HomePageCards() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tax = useSelector((state) => state.totalIncomeCardReducer.tax);

  const onTaxClick = () => {
    dispatch(setTax(tax === TAX.EXCLUDED ? TAX.INCLUDED : TAX.EXCLUDED));
  };

  useEffect(() => {
    dispatch(getConsolidatedIncome({ tax }));
    dispatch(getTopPercentageGrowth({ tax }));
    dispatch(getConsolidatedFood({ tax }));
  }, [tax, dispatch]);

  return (
    <div className='HomePageCards'>
      {/* Toggle tax display */}
      <div className='TopCard__tax' onClick={onTaxClick}>
        {t(tax === TAX.EXCLUDED ? TAX.EXCLUDED : TAX.INCLUDED)}
      </div>
      <div className='HomePageCards__wrapper'>
        {/* Display different types of TopCards */}
        <TopCard type={TOP_CARD_TYPES.CONSOLIDATED_INCOME} />
        <TopCard type={TOP_CARD_TYPES.TOP_PERCENTAGE_GROWTH} />
        <TopCard type={TOP_CARD_TYPES.CONSOLIDATED_FOOD} />
      </div>
    </div>
  );
}

export default HomePageCards;
