// src/assets/icons/Dashboard.jsx

import React from 'react';
import { Colors } from '../../utils/colors';

const Dashboard = ({ selected, color }) => {
  return (
    <svg width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={selected ? Colors.white : color}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M8.002 2.52A6.002 6.002 0 0114.5 8.5a6.002 6.002 0 01-11.715 1.833'
      ></path>
      <path
        stroke={selected ? Colors.white : color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity={selected ? '1' : '0.4'}
        d='M6 2C3.238 2 1 4.225 1 6.97c-.001.7.147 1.391.435 2.03L7 7.5 6 2z'
      ></path>
    </svg>
  );
};

export default Dashboard;
