// src/services/Saga/getReportingList/index.js

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import { setReportingList } from '../../Redux/reportingListReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';
import dayjs from 'dayjs';

export function* watcherGetReportingList() {
  yield takeEvery(ActionTypes.GET_REPORTING_LIST, workerGetReportingList);
}

export function* workerGetReportingList({ payload }) {
  try {
    const { filters, reportingList } = yield select((state) => ({
      filters: state.reportingListReducer.filters,
      reportingList: state.reportingListReducer.reportingList,
    }));

    const { page = 1 } = payload;
    const { selectedMallId, selectedOrder, storeFetchDates } = filters;

    const from = dayjs(storeFetchDates[0]).format('YYYY-MM-DD');
    const to = dayjs(storeFetchDates[1]).format('YYYY-MM-DD');

    const queryParams = new URLSearchParams({
      page,
      limit: 150,
      from,
      to,
      ...(selectedMallId && { mallIds: selectedMallId }),
      ...(selectedOrder && { order: selectedOrder }),
    }).toString();

    const url = `${MAIN_URL}/v1/stores/incomes/reports?${queryParams}`;
    const res = yield call(axiosInstance.get, url);

    const newData = page > 1 ? { ...res, data: [...reportingList, ...res.data] } : res;
    yield put(setReportingList(newData));
  } catch (error) {
    yield put(setReportingList([]));
  }
}
