// src/utils/showNotification.js

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NOTIFICATION_CSS_CLASSES } from './constants';

const defaultOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'dark',
};

const showNotification = ({ content, options = {}, type }) => {
  toast[type](content, {
    ...defaultOptions,
    className: NOTIFICATION_CSS_CLASSES[type],
    ...options,
  });
};

export default showNotification;
