// src/pages/AdminPanel/pages/user/UserInfo/EditForm.jsx

import React, { useState } from 'react';
import GrayButton from '../../../../../components/GrayButton';
import { useTranslation } from 'react-i18next';
import AdminEditFormInput, {
  AdminEditFormPhoneInput,
} from '../../../components/AdminEditFormInput';
import AdminEditFormSelect from '../../../components/AdminEditFormSelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ROLES, roles } from '../../../utils/formatForTables';
import AdminApi from '../../../../../services/Axios/AdminApi';
import AdminEditFormMultipleSelect from '../../../components/AdminEditFormMultipleSelect';

function EditForm({ user, onSave, onCancel, malls }) {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);

  const initialValue = {
    name: user.name,
    surname: user.surname,
    phoneNumber: user.phoneNumber,
    email: user.email,
    password: user.password,
    role: user.roles[0],
    malls:
      user.roles[0] !== ROLES.STORE_MANAGER
        ? user.malls.map((mall) => mall.id)
        : user?.store?.mallId
          ? [user?.store?.mallId]
          : [],
    store: user.storeId,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .trim('validation.trimError')
      .matches(
        /^[a-zA-ZæøåÆØÅ _]*[a-zA-ZæøåÆØÅ]+$/,
        t('validation.shouldBeText'),
      )
      .required(t('validation.required')),
    surname: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(25, t('validation.tooLong'))
      .trim('validation.trimError')
      .matches(
        /^[a-zA-ZæøåÆØÅ _]*[a-zA-ZæøåÆØÅ]+$/,
        t('validation.shouldBeText'),
      )
      .required(t('validation.required')),
    phoneNumber: Yup.string()
      .test('len', t('validation.invalidPhone'), (val) => {
        return val.replaceAll('_', '').replaceAll(' ', '').length >= 11;
      })
      .required(t('validation.required')),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
    role: Yup.string().required(t('validation.required')),
    password: Yup.string()
      .min(8, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .required(t('validation.required')),
  });

  const onInnerSubmit = (values) => {
    const formattedValues = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      phoneNumber: values.phoneNumber.replaceAll(' ', ''),
      roles: [values.role],
      storeId: values.store || undefined,
      password: values.password,
      mallIds: values.malls.length > 0 ? values.malls : undefined,
    };
    return onSave(formattedValues);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: initialValue,
    onSubmit: onInnerSubmit,
    validationSchema,
  });

  React.useEffect(() => {
    if (values.malls[0]) {
      AdminApi.getStores({ mallId: values.malls[0], available: true }).then(
        (res) => {
          setStores(res.data);
        },
      );
    }
  }, [values.malls[0]]);

  return (
    <div className='EditForm'>
      <AdminEditFormInput
        name={t('adminTable.name')}
        placeholder={t('adminTable.name')}
        value={values.name}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        error={touched.name && errors.name}
      />
      <AdminEditFormInput
        name={t('adminTable.surname')}
        placeholder={t('adminTable.surname')}
        value={values.surname}
        onChange={handleChange('surname')}
        onBlur={handleBlur('surname')}
        error={touched.surname && errors.surname}
      />
      <AdminEditFormPhoneInput
        name={t('adminTable.phoneNumber')}
        placeholder={t('adminTable.phoneNumber')}
        value={values.phoneNumber}
        onChange={(val) => setFieldValue('phoneNumber', val)}
        onBlur={handleBlur('phoneNumber')}
        error={touched.phoneNumber && errors.phoneNumber}
      />
      <AdminEditFormInput
        name={t('adminTable.email')}
        placeholder={t('adminTable.email')}
        value={values.email}
        onChange={handleChange('email')}
        onBlur={handleBlur('email')}
        error={touched.email && errors.email}
      />
      <AdminEditFormInput
        name={t('adminTable.password')}
        placeholder={t('adminTable.password')}
        value={values.password}
        onChange={handleChange('password')}
        onBlur={handleBlur('password')}
        error={touched.password && errors.password}
      />
      <AdminEditFormSelect
        name={t('adminTable.userRole')}
        placeholder={t('adminTable.userRole')}
        value={values.role}
        options={Object.entries(roles).map(([value, label]) => ({
          label,
          value,
        }))}
        onChange={handleChange('role')}
        onBlur={handleBlur('role')}
        error={touched.role && errors.role}
      />
      {values.role !== ROLES.STORE_MANAGER ? (
        <AdminEditFormMultipleSelect
          name={t('adminTable.mall')}
          values={values.malls}
          options={malls.map((mall) => ({
            value: mall.id,
            label: mall.name,
          }))}
          onChange={(val) => setFieldValue('malls', val)}
          onBlur={handleBlur('malls')}
          error={touched.malls && errors.malls}
        />
      ) : (
        <AdminEditFormSelect
          name={t('adminTable.mall')}
          placeholder={t('adminTable.mall')}
          value={values.malls[0]}
          options={malls.map((mall) => ({
            value: mall.id,
            label: mall.name,
          }))}
          onChange={(val) => setFieldValue('malls', [val])}
          onBlur={handleBlur('malls')}
          error={touched.malls && errors.malls}
        />
      )}
      <AdminEditFormSelect
        name={t('adminTable.store')}
        placeholder={t('adminTable.store')}
        value={values.store}
        onChange={(val) => setFieldValue('store', val)}
        options={stores.map((store) => ({
          value: store.id,
          label: store.name,
        }))}
        onBlur={handleBlur('store')}
        error={touched.store && errors.store}
        disabled={values.role !== ROLES.STORE_MANAGER}
      />
      <div className='EditForm__buttons'>
        <GrayButton onClick={onCancel}>{t('cancel')}</GrayButton>
        <GrayButton onClick={handleSubmit} loading={isSubmitting}>
          {t('save')}
        </GrayButton>
      </div>
    </div>
  );
}

export default EditForm;
