// src/assets/icons/Delete.jsx

import * as React from 'react';
const Delete = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='m4.375 4.375.781 12.5c.037.722.563 1.25 1.25 1.25h7.188c.69 0 1.206-.528 1.25-1.25l.781-12.5'
    />
    <path fill='#FAFAFA' fillOpacity={0.72} d='M3.125 4.375h13.75-13.75Z' />
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeOpacity={0.72}
      d='M3.125 4.375h13.75'
    />
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='M7.5 4.375V2.812a.935.935 0 0 1 .938-.937h3.124a.935.935 0 0 1 .938.938v1.562m-2.5 2.5v8.75m-2.813-8.75.313 8.75m5.313-8.75-.313 8.75'
    />
  </svg>
);
export default Delete;
