// src/assets/icons/Sort.jsx

import * as React from 'react';
const Sort = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={12}
    height={12}
    fill='none'
    {...props}
  >
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M10.875 4.875 8.25 2.25 5.625 4.875M8.25 2.651V9.75M1.125 7.125 3.75 9.75l2.625-2.625M3.75 9.328V2.25'
    />
  </svg>
);
export default Sort;
