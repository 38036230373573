// src/components/IncomeTable/utils/changeSavedTables.js

import { cloneDeep } from 'lodash';
import axiosInstance from '../../../services/Axios/axiosInstance';

const changeSavedTables = (newTables) => {
  const copiedTables = cloneDeep(newTables);
  Object.keys(copiedTables).forEach((tableUID) => {
    delete copiedTables[tableUID]?.malls;
  });
  axiosInstance.post('/users/settings', {
    settings: JSON.stringify(copiedTables),
    //settings: copiedTables,
  });
};

export default changeSavedTables;
