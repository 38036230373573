// src/services/Redux/mallsReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setMalls = (payload) => createAction(ActionTypes.SET_MALLS, payload);
export const setStores = (payload) => createAction(ActionTypes.SET_STORES, payload);
export const setStoresNewUser = (payload) => createAction(ActionTypes.SET_STORES_NEW_USER, payload);
export const setStoreGroups = (payload) => createAction(ActionTypes.SET_STORE_GROUPS, payload);
