// src/services/Redux/leftMenuReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setVisible = (payload) => createAction(ActionTypes.SET_IS_VISIBLE, payload);
export const setVisibleCharts = (payload) => createAction(ActionTypes.SET_IS_VISIBLE_CHARTS, payload);
