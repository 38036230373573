// src/components/IncomeTable/utils/exportTable.js

import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { store } from './../../../services';
import { TOP_CARD_TYPES } from '../../../services/Redux/totalIncomeCardsReducer';
import addSpaces from '../../../utils/addSpaces';
import { shouldDisplayPercents } from './otherUtils';
import { getDiffPercentage, getShouldDisplayDiff } from './getDiff';
import { getColumnTotal } from './getTotal';
import { getShownMalls, getShownStores } from './getShown';
import axiosInstance from '../../../services/Axios/axiosInstance';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { ROLES } from '../../../pages/AdminPanel/utils/formatForTables';
import getDisabledStores, { getIsStoreDisabled } from './getDisabledStore';

const exportTable = async (tableSettings, setProgress) => {
  // create and generate tables
  const workbook = new Excel.Workbook();
  const dashboard = workbook.addWorksheet('Dashboard');
  await generateIncomeCards(dashboard);
  setProgress(5);
  await generateIncomeTable(dashboard, tableSettings);
  setProgress(15);
  await generateStoreIncomeTables(workbook, setProgress);

  workbook.eachSheet((sheet) =>
    sheet.columns.forEach((col) => (col.width = 17)),
  );
  // download
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Dashboard.xlsx');
  });
};

const generateIncomeCards = (dashboard) => {
  const state = store.getState();
  const tax = state.totalIncomeCardReducer.tax;
  const consolidatedIncome =
    state.totalIncomeCardReducer[TOP_CARD_TYPES.CONSOLIDATED_INCOME];
  const topPercentageGrowth =
    state.totalIncomeCardReducer[TOP_CARD_TYPES.TOP_PERCENTAGE_GROWTH];
  const consolidatedFood =
    state.totalIncomeCardReducer[TOP_CARD_TYPES.CONSOLIDATED_FOOD];

  dashboard.getCell('A1').value = capitalizeFirstLetter(tax);

  dashboard.mergeCells('A3:B3');

  dashboard.getCell('A3').value = makeBold(t('consolidatedIncome'));

  dashboard.getCell('A4').value = formatDate(
    consolidatedIncome?.periodDate?.[0],
  );
  dashboard.getCell('B4').value = consolidatedIncome?.period
    ? addSpaces(consolidatedIncome?.period)
    : '';
  dashboard.getCell('B4').alignment = { horizontal: 'right' };

  dashboard.getCell('C4').value = `${Number(
    consolidatedIncome?.difference,
  ).toFixed(2)} %`;
  dashboard.getCell('C4').alignment = { horizontal: 'right' };

  dashboard.getCell('A5').value = formatDate(
    consolidatedIncome?.prevPeriodDate?.[0],
  );
  dashboard.getCell('B5').value = consolidatedIncome?.prevPeriod
    ? addSpaces(consolidatedIncome?.prevPeriod)
    : '';
  dashboard.getCell('B5').alignment = { horizontal: 'right' };

  dashboard.mergeCells('E3:F3');
  dashboard.getCell('E3').value = makeBold(t('topPercentageGrowth'));

  dashboard.getCell('E4').value = formatDate(
    topPercentageGrowth?.periodDate?.[0],
  );
  (dashboard.getCell('F4').value = topPercentageGrowth?.period
    ? addSpaces(topPercentageGrowth?.period)
    : ''),
    (dashboard.getCell('F4').alignment = { horizontal: 'right' });

  dashboard.getCell('G4').value = `${Number(
    topPercentageGrowth?.difference,
  ).toFixed(2)} %`;
  dashboard.getCell('G4').alignment = { horizontal: 'right' };

  dashboard.getCell('E5').value = formatDate(
    topPercentageGrowth?.prevPeriodDate?.[0],
  );
  dashboard.getCell('F5').value = topPercentageGrowth?.prevPeriod
    ? addSpaces(topPercentageGrowth?.prevPeriod)
    : '';
  dashboard.getCell('F5').alignment = { horizontal: 'right' };

  dashboard.getCell('F4').alignment = { horizontal: 'right' };

  dashboard.mergeCells('I3:K3');
  dashboard.getCell('I3').value = makeBold(t('consolidatedFood'));

  dashboard.getCell('I4').value = formatDate(consolidatedFood?.periodDate?.[0]);
  dashboard.getCell('J4').value = consolidatedFood?.period
    ? addSpaces(consolidatedFood?.period)
    : '';
  dashboard.getCell('J4').alignment = { horizontal: 'right' };

  dashboard.getCell('K4').value = `${Number(
    consolidatedFood?.difference,
  ).toFixed(2)} %`;
  dashboard.getCell('K4').alignment = { horizontal: 'right' };

  dashboard.getCell('I5').value = formatDate(
    consolidatedFood?.prevPeriodDate?.[0],
  );
  dashboard.getCell('J5').value = consolidatedIncome?.prevPeriod
    ? addSpaces(consolidatedFood?.prevPeriod)
    : '';
  dashboard.getCell('J5').alignment = { horizontal: 'right' };

  return dashboard;
};

const generateIncomeTable = async (dashboard, tableSettings) => {
  let startRow = TABLE_START + TABLE_MARGIN;
  Object.entries(tableSettings).forEach(([tableUID, table], index) => {
    const tableStart = startRow;
    // EACH TABLE

    const shouldDisplayDiff = getShouldDisplayDiff(table.columns);
    const shownColumns = table.columns.filter(
      (x) => x.isVisible && (x.isDiff ? shouldDisplayDiff : true),
    );

    const allShownMalls = getShownMalls({
      malls: table.malls,
      isEdit: false,
      hiddenMalls: table.hiddenMalls,
    });

    const allShownStores = allShownMalls.reduce(
      (cur, mall) => [
        ...cur,
        ...getShownStores({
          stores: mall.stores,
          isEdit: false,
          hiddenStores: table.hiddenStores,
        }),
      ],
      [],
    );

    const totals = shownColumns.map((column) => ({
      ...column,
      data: getColumnTotal(
        allShownStores,
        column,
        shownColumns.find((x) => x.isVisible),
      ),
    }));

    dashboard.mergeCells(`A${tableStart}:A${tableStart + 2}`);

    const splicedABC = ABCuntilG.toSpliced(shownColumns.length + 1);
    const { sortedMalls } = table;

    const totalTableLength =
      sortedMalls.length +
      sortedMalls.reduce(
        (accumulator, mall) => accumulator + mall.shownStores.length,
        0,
      ) +
      1 + // 1 for total
      3; // 3 for table header

    splicedABC.forEach((col, colIndex) => {
      let currentRow = startRow;
      // EACH COLUMN

      const currentColumn = shownColumns[colIndex - 1];

      // draw table header start

      // dates
      dashboard.getCell(col + currentRow).value =
        getFormattedDateFromColumnData(currentColumn);
      currentRow++;
      // type
      dashboard.getCell(col + currentRow).value =
        getTypeFromColumnData(currentColumn);
      currentRow++;
      // tax
      dashboard.getCell(col + currentRow).value =
        getTaxFromColumnData(currentColumn);
      currentRow++;

      // draw data table

      sortedMalls.forEach((mall) => {
        // EACH MALL

        // draw mall

        dashboard.getCell(col + currentRow).value = makeBold(
          colIndex === 0
            ? mall.name
            : addSpaces(
                Number(
                  getColumnTotal(
                    mall.shownStores,
                    currentColumn,
                    shownColumns.find((x) => x.isVisible),
                  ),
                ).toFixed(2),
                { hideSecond: true },
              ),
        );
        if (colIndex !== 0)
          dashboard.getCell(col + currentRow).alignment = {
            horizontal: 'right',
          };
        currentRow++;

        const disabledStores = getDisabledStores(
          mall.shownStores,
          mall.sortedStoreColumns,
          shownColumns,
        );

        // draw stores
        mall.shownStores
          .filter((store) => !disabledStores.find((x) => x === store.id))
          .forEach((store) => {
            let cellData = '';
            if (colIndex === 0) cellData = store.name;
            else {
              let formattedCellData = addSpaces(
                mall.sortedStoreColumns[currentColumn.columnUID][store.id]
                  .cellData,
                { hideSecond: true },
              );
              cellData = getIsStoreDisabled({
                shownColumns,
                columnUID: currentColumn.columnUID,
                sortedStoreColumns: mall.sortedStoreColumns,
                storeId: store.id,
              })
                ? t('notActive')
                : `${formattedCellData}${
                    shouldDisplayPercents(
                      shownColumns,
                      currentColumn.columnUID,
                      formattedCellData,
                    )
                      ? ' %'
                      : ''
                  }`;
              dashboard.getCell(col + currentRow).alignment = {
                horizontal: 'right',
              };
            }
            dashboard.getCell(col + currentRow).value = cellData;
            currentRow++;
          });

        // draw total
        dashboard.getCell(col + currentRow).value = makeBold(
          colIndex === 0
            ? 'Total'
            : currentColumn?.diffType === 'percentage'
              ? getDiffPercentage(totals[0].data, totals[1].data) === '-'
                ? '0'
                : getDiffPercentage(totals[0].data, totals[1].data) + ' %'
              : addSpaces(totals[colIndex - 1].data, { hideSecond: true }),
        );
        if (colIndex !== 0)
          dashboard.getCell(col + currentRow).alignment = {
            horizontal: 'right',
          };
      });
    });

    startRow += totalTableLength + TABLE_MARGIN;
  });

  return dashboard;
};

const generateStoreIncomeTables = async (workbook, setProgress) => {
  const mallsList = await axiosInstance
    .get('/malls', { params: { limit: 20 } })
    .then((res) => res.data)
    .catch(() => []);

  let stores = [];
  for (let i = 0; i < mallsList.length; i++) {
    stores.push(
      await getStoreIncomesReports(
        mallsList[i].id,
        setProgress,
        mallsList.length,
      ),
    );
    await sleepFor(2500);
  }

  const malls = mallsList.map((mall, index) => ({
    ...mall,
    ...stores[index],
  }));

  malls.forEach((mall) => {
    const user = store.getState().loginReducer.user;

    const stores =
      user.roles[0] === ROLES.STORE_MANAGER
        ? mall.stores.filter((x) => x.id === user.storeId)
        : mall.stores;

    if (stores.length === 0) return;
    const mallWorksheet = workbook.addWorksheet(mall.name);

    // draw mall name
    const totalLength = 8 + stores.length * 2;
    const columns = generateColumnsArray(totalLength);

    selectRange(
      mallWorksheet,
      `A3:${columns[totalLength] + (stores[0]?.reports?.length + 3)}`,
    ).forEach(
      (col) =>
        (col.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }),
    );

    createOuterBorder(
      mallWorksheet,
      { row: 3, col: 1 },
      { row: 3 + (stores[0]?.reports?.length || 0) - 1, col: totalLength },
      'thick',
    );

    createOuterBorder(
      mallWorksheet,
      { row: 3, col: 1 },
      {
        row: 3,
        col: totalLength,
      },
    );

    createOuterBorder(
      mallWorksheet,
      { row: 2, col: 5 },
      {
        row: 3 + (stores[0]?.reports?.length || 0),
        col: stores.length + 4,
      },
      'thick',
    );

    createOuterBorder(
      mallWorksheet,
      { row: 2, col: stores.length + 9 },
      {
        row: 3 + (stores[0]?.reports?.length || 0),
        col: totalLength,
      },
      'thick',
    );

    mallWorksheet.mergeCells(`A1:${columns[totalLength - 1]}1`);
    mallWorksheet.getCell('A1').value = mall.name;
    mallWorksheet.getCell('A1').alignment = { horizontal: 'center' };

    // draw second and third rows
    const startIncluded = 4;
    const startExcluded = stores.length + 8;

    mallWorksheet.getCell(`A3`).value = t('year');
    mallWorksheet.getCell(`B3`).value = t('month');
    mallWorksheet.getCell(`C3`).value = t('week');
    mallWorksheet.getCell(`D3`).value = t('date');

    mallWorksheet.getCell(`${columns[startExcluded - 4]}3`).value = t('year');
    mallWorksheet.getCell(`${columns[startExcluded - 3]}3`).value = t('month');
    mallWorksheet.getCell(`${columns[startExcluded - 2]}3`).value = t('week');
    mallWorksheet.getCell(`${columns[startExcluded - 1]}3`).value = t('date');

    stores.forEach((store, index) => {
      const storeNameCellInc = mallWorksheet.getCell(
        `${columns[startIncluded + index]}2`,
      );
      mallWorksheet.getCell(`${columns[startIncluded + index]}3`).value =
        t('included');

      const storeNameCellExc = mallWorksheet.getCell(
        `${columns[startExcluded + index]}2`,
      );
      mallWorksheet.getCell(`${columns[startExcluded + index]}3`).value =
        t('excluded');

      storeNameCellInc.value = makeBold(store.name);
      storeNameCellInc.alignment = { horizontal: 'center' };

      storeNameCellExc.value = makeBold(store.name);
      storeNameCellExc.alignment = { horizontal: 'center' };
    });

    // draw table

    const yearLength = stores[0]?.reports?.length + 3;

    for (let row = 4; row < yearLength; row++) {
      // EACH REPORT (ROW)

      for (let col = 0; col < totalLength; col++) {
        const currentCell = mallWorksheet.getCell(`${columns[col]}${row}`);

        if (col === 0 || col === startExcluded - 4)
          currentCell.value = dayjs().format('YYYY');

        if (col === 1 || col === startExcluded - 3)
          currentCell.value = t(
            dayjs(stores[0]?.reports[row - 4].date)
              .format('MMMM')
              .toLowerCase(),
          );

        if (col === 2 || col === startExcluded - 2)
          currentCell.value = `${t('week')} ${
            stores[0]?.reports[row - 4].week
          }`;

        if (col === 3 || col === startExcluded - 1)
          currentCell.value = formatDate(stores[0]?.reports[row - 4].date);

        if (col > 3 && col < startExcluded - 4) {
          currentCell.value = stores[col - 4]?.reports[row - 4].included;
        }

        if (col > startExcluded - 1 && col < totalLength) {
          currentCell.value =
            stores[col - startExcluded]?.reports[row - 4].excluded;
        }
      }
    }

    // draw total

    for (let i = 0; i < stores?.length; i++) {
      mallWorksheet.getCell(
        `${columns[startIncluded + i]}${yearLength}`,
      ).value = {
        formula: `SUM(${columns[startIncluded + i]}4:${
          columns[startIncluded + i]
        }${yearLength - 1})`,
      };
      mallWorksheet.getCell(
        `${columns[startExcluded + i]}${yearLength}`,
      ).value = {
        formula: `SUM(${columns[startExcluded + i]}4:${
          columns[startExcluded + i]
        }${yearLength - 1})`,
      };
    }
  });
};

export default exportTable;

const TABLE_START = 6;
const TABLE_MARGIN = 2;
const ABC = 'abcdefghijklmnopqrstuvwxyz'.split('').map((x) => x.toUpperCase());
const ABCuntilG = ABC.toSpliced(7); // for income tables

const formatDate = (date) => {
  const arr = date.split('-').reverse();
  arr[arr.length - 1] = arr.at(-1).slice(2, 4);
  return arr.join('.');
};

const getFormattedDateFromColumnData = (column) => {
  if (column?.from)
    return `${formatDate(column.from)}-${formatDate(column.to)}`;
  if (column?.isDiff) {
    if (column?.diffType === 'numbers') return 'Diff';
    return 'Diff %';
  }
};

const getTypeFromColumnData = (column) => {
  if (column?.type) return capitalizeFirstLetter(column.type);
  return '';
};

const getTaxFromColumnData = (column) => {
  if (column?.tax) return capitalizeFirstLetter(t(column.tax));
  return '';
};

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const makeBold = (text) => ({
  richText: [
    {
      text,
      font: { bold: true },
    },
  ],
});

const generateColumnsArray = (totalLength) => {
  let cols = [...ABC];
  for (let i = 0; i < ABC.length; i++) {
    for (let x = 0; x < ABC.length; x++) {
      if (ABC.length >= totalLength) break;
      cols.push(ABC[i] + ABC[x]);
    }
  }
  return cols;
};

const getStoreIncomesReportsSingle = (storeId, page = 1) => {
  return axiosInstance
    .get(`/incomes/stores/${storeId}/reports`, {
      params: {
        from: dayjs().startOf('year').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
        limit: 370,
        page,
      },
    })
    .then((res) => res.data);
};

const getMallStores = (mallId, page = 1) => {
  return axiosInstance
    .get(`/stores/malls/${mallId}`, {
      params: {
        limit: 100,
        page,
      },
    })
    .then(async (res) => {
      if ((page - 1) * 100 + res.data.length >= res.total) return res.data;

      return [...res.data, ...(await getMallStores(mallId, page + 1))];
    });
};

const getStoreIncomesReports = async (mallId, setProgress, mallsLength) => {
  const stores = await getMallStores(mallId).catch(() => []);

  const storesInChunks = formChunks(stores);
  let storesReports = [];

  for (let i = 0; i < storesInChunks.length; i++) {
    const storeReportsPromises = storesInChunks[i].map(
      (store) => getStoreIncomesReportsSingle(store.id),
      /*.then((res) =>
        res.filter((x) => dayjs(x.date).isBefore(dayjs().add(1, 'day'))),
      ),
      */
    );

    await sleepFor(2500);

    storesReports.push(await Promise.all(storeReportsPromises));
    const inThisMallStoresPatch =
      (85 / mallsLength) * (storeReportsPromises.length / stores.length);
    setProgress((p) => p + inThisMallStoresPatch);
  }

  storesReports = storesReports.flat();

  return {
    mallId,
    stores: stores.map((store, index) => ({
      ...store,
      reports: storesReports[index],
    })),
  };
};

const createOuterBorder = (
  worksheet,
  start = { row: 1, col: 1 },
  end = { row: 1, col: 1 },
  borderWidth = 'medium',
) => {
  const borderStyle = {
    style: borderWidth,
  };
  for (let i = start.row; i <= end.row; i++) {
    const leftBorderCell = worksheet.getCell(i, start.col);
    const rightBorderCell = worksheet.getCell(i, end.col);
    leftBorderCell.border = {
      ...leftBorderCell.border,
      left: borderStyle,
    };
    rightBorderCell.border = {
      ...rightBorderCell.border,
      right: borderStyle,
    };
  }

  for (let i = start.col; i <= end.col; i++) {
    const topBorderCell = worksheet.getCell(start.row, i);
    const bottomBorderCell = worksheet.getCell(end.row, i);
    topBorderCell.border = {
      ...topBorderCell.border,
      top: borderStyle,
    };
    bottomBorderCell.border = {
      ...bottomBorderCell.border,
      bottom: borderStyle,
    };
  }
};

const selectRange = (sheet, rangeCell) => {
  let [startCell, endCell] = rangeCell.split(':');

  // Recalculate in case bottom left and top right are given
  if (endCell < startCell) {
    let temp = endCell;
    endCell = startCell;
    startCell = temp;
  }

  let [endCellColumn, endRow] = endCell.match(/[a-z]+|[^a-z]+/gi);
  let [startCellColumn, startRow] = startCell.match(/[a-z]+|[^a-z]+/gi);

  // Recalculate in case bottom left and top right are given
  if (endCellColumn < startCellColumn) {
    let temp = endCellColumn;
    endCellColumn = startCellColumn;
    startCellColumn = temp;
  }

  // Recalculate in case bottom left and top right are given
  if (endRow < startRow) {
    let temp = endRow;
    endRow = startRow;
    startRow = temp;
  }

  let endColumn = sheet.getColumn(endCellColumn);
  let startColumn = sheet.getColumn(startCellColumn);

  if (!endColumn) throw new Error('End column not found');
  if (!startColumn) throw new Error('Start column not found');

  const endColumnNumber = endColumn.number;
  const startColumnNumber = startColumn.number;

  const cells = [];
  for (let y = parseInt(startRow); y <= parseInt(endRow); y++) {
    const row = sheet.getRow(y);

    for (let x = startColumnNumber; x <= endColumnNumber; x++) {
      cells.push(row.getCell(x));
    }
  }

  return cells;
};

const sleepFor = async (sleepDuration) =>
  new Promise((resolve) => {
    setTimeout(resolve, sleepDuration);
  });

const formChunks = (array) => {
  const chunkSize = 5;
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};
