import React, { useState, useRef, useEffect, useLayoutEffect, useCallback, useMemo } from 'react';
import useDebounce from './useDebounce';
import './style.scss';

const useTouchScroll = (isVertical) => {
  const touchStartRef = useRef(null);
  const touchStartTime = useRef(null);
  const scrollVelocityRef = useRef(0);
  const wheelPickerRef = useRef();

  const debounceScroll = useDebounce((newScroll, isVertical) => {
    if (isVertical) {
      wheelPickerRef.current.scrollTop = newScroll;
    } else {
      wheelPickerRef.current.scrollLeft = newScroll;
    }
  }, 16); // Approximately 60 FPS

  const handleTouchStart = (event) => {
    touchStartTime.current = Date.now();
    touchStartRef.current = isVertical ? event.touches[0].clientY : event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    const touchMove = isVertical ? event.touches[0].clientY : event.touches[0].clientX;
    const distanceMoved = touchStartRef.current - touchMove;
    const maxScroll = isVertical ? wheelPickerRef.current.scrollHeight - wheelPickerRef.current.clientHeight : wheelPickerRef.current.scrollWidth - wheelPickerRef.current.clientWidth;
    let newScroll = (isVertical ? wheelPickerRef.current.scrollTop : wheelPickerRef.current.scrollLeft) + distanceMoved;
    newScroll = Math.max(0, Math.min(newScroll, maxScroll));

    debounceScroll(newScroll, isVertical);

    touchStartRef.current = touchMove;
  };

  const handleTouchEnd = (event) => {
    const velocity = (touchStartRef.current - (isVertical ? event.changedTouches[0].clientY : event.changedTouches[0].clientX)) / (Date.now() - touchStartTime.current);
    scrollVelocityRef.current = velocity;
    requestAnimationFrame(fling);
  };

  const fling = () => {
    if (Math.abs(scrollVelocityRef.current) < 0.01) {
      scrollVelocityRef.current = 0;
    } else {
      let newScroll = (isVertical ? wheelPickerRef.current.scrollTop : wheelPickerRef.current.scrollLeft) + scrollVelocityRef.current;
      const maxScroll = isVertical ? wheelPickerRef.current.scrollHeight - wheelPickerRef.current.clientHeight : wheelPickerRef.current.scrollWidth - wheelPickerRef.current.clientWidth;
      newScroll = Math.max(0, Math.min(newScroll, maxScroll));
      const optionHeight = wheelPickerRef.current.firstChild.offsetHeight;
      const nearestOptionStart = Math.round(newScroll / optionHeight) * optionHeight;
      if (Math.abs(newScroll - nearestOptionStart) < optionHeight * 0.01) {
        newScroll = nearestOptionStart;
        scrollVelocityRef.current = 0;
      }
      if (isVertical) {
        wheelPickerRef.current.scrollTop = newScroll;
      } else {
        wheelPickerRef.current.scrollLeft = newScroll;
      }
      if (scrollVelocityRef.current !== 0) {
        scrollVelocityRef.current *= 0.95;
        requestAnimationFrame(fling);
      }
    }
  };

  return {
    wheelPickerRef,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

const VerticalWheelPicker = React.memo(({ options, selectedValue, onChange, renderOption }) => {
  const [selectedOption, setSelectedOption] = useState(selectedValue);
  const optionRefs = useRef([]);
  const { wheelPickerRef, handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchScroll(true);
  const initialRender = useRef(true);

  optionRefs.current = options.map((_, i) => optionRefs.current[i] ?? React.createRef());

  const selectedIndex = useMemo(() => options.findIndex(option => option.value === selectedValue), [options, selectedValue]);

  const initialScrollPosition = useMemo(() => {
    if (selectedIndex >= 0 && optionRefs.current[selectedIndex] && optionRefs.current[selectedIndex].current) {
      return optionRefs.current[selectedIndex].current.offsetTop - wheelPickerRef.current.clientHeight / 2 + optionRefs.current[selectedIndex].current.clientHeight / 2;
    }
    return 0;
  }, [selectedIndex, options]);

  useLayoutEffect(() => {
    setSelectedOption(selectedValue);

    if (initialScrollPosition !== 0) {
      wheelPickerRef.current.scrollTo({
        top: initialScrollPosition,
        behavior: initialRender.current ? 'auto' : 'smooth',
      });
      initialRender.current = false;
    }
  }, [selectedValue, initialScrollPosition]);

  const handleChange = useCallback((value, index) => {
    if (value !== undefined && value !== selectedOption) {
      setSelectedOption(value);
      onChange(value);
    }
  }, [selectedOption, onChange]);

  const debounceHandleScroll = useDebounce((scrollTop, optionHeight) => {
    const centerPosition = scrollTop + wheelPickerRef.current.clientHeight / 2;
    const closestIndex = optionRefs.current.reduce((closest, ref, index) => {
      if (!ref.current) return closest;
      const offsetTop = ref.current.offsetTop + optionHeight / 2;
      const distance = Math.abs(centerPosition - offsetTop);
      return distance < closest.distance ? { index, distance } : closest;
    }, { index: -1, distance: Infinity }).index;

    if (closestIndex >= 0 && options[closestIndex] && options[closestIndex].value !== selectedOption) {
      handleChange(options[closestIndex].value, closestIndex);
    }
  }, 50);

  const handleScroll = useCallback((event) => {
    const scrollTop = event.target.scrollTop;
    const optionHeight = optionRefs.current[0]?.current?.offsetHeight || 0;
    debounceHandleScroll(scrollTop, optionHeight);
  }, [debounceHandleScroll, options, selectedOption]);

  const debounceHandleWheel = useDebounce((newScroll) => {
    wheelPickerRef.current.scrollTo({
      top: newScroll,
      behavior: 'smooth',
    });
  }, 16);

  const handleWheel = useCallback((event) => {
    event.preventDefault();
    const optionHeight = optionRefs.current[0]?.current?.offsetHeight || 0;
    const delta = Math.sign(event.deltaY);
    const scrollAmount = optionHeight;
    let newScroll = wheelPickerRef.current.scrollTop + delta * scrollAmount;
    const maxScroll = wheelPickerRef.current.scrollHeight - wheelPickerRef.current.clientHeight;
    newScroll = Math.max(0, Math.min(newScroll, maxScroll));

    debounceHandleWheel(newScroll);
  }, [debounceHandleWheel]);

  useEffect(() => {
    const wheelPickerElement = wheelPickerRef.current;
    wheelPickerElement.addEventListener('wheel', handleWheel);
    wheelPickerElement.addEventListener('scroll', handleScroll);

    return () => {
      wheelPickerElement.removeEventListener('wheel', handleWheel);
      wheelPickerElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleWheel, handleScroll]);

  return (
    <div className='wheel-picker-container vertical-wheel-picker'>
      <div className='wheel-picker' ref={wheelPickerRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        <div className='wheel-picker-inner'>
          {options.map((option, index) => {
            const classNames = ['wheel-picker-option', 'vertical-option'];
            if (selectedOption === option.value) {
              classNames.push('selected');
            } else {
              if (Math.abs(selectedIndex - index) === 1) {
                classNames.push('adjacent');
              } else if (Math.abs(selectedIndex - index) === 2) {
                classNames.push('second-adjacent');
              }
            }
            return (
              <div key={index} ref={optionRefs.current[index]} className={classNames.join(' ')} onClick={() => handleChange(option.value, index)}>
                {renderOption ? renderOption(option) : option.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default VerticalWheelPicker;
