// src/services/Saga/createNewMallAndStore/index.js

import { call, select, takeEvery } from 'redux-saga/effects';
import showNotification from '../../../utils/showNotification';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherCreateMall() {
  yield takeEvery(ActionTypes.CREATE_MALL, workerCreateMall);
}

export function* workerCreateMall({ payload }) {
  const body = {
    name: payload.name,
  };
  const res = yield call(axiosInstance.post, `/admins/malls`, body);
  if (res?.response?.data?.userMessage) {
    showNotification({
      content: res?.response?.data?.userMessage,
      type: 'error',
    });
  }
  if (res.createdAt) {
    showNotification({ content: 'Mall Created', type: 'success' });
    payload.handler(false);
  }
}

export function* watcherCreateStore() {
  yield takeEvery(ActionTypes.CREATE_STORE, workerCreateStore);
}

export function* workerCreateStore({ payload }) {
  const body = {
    name: payload.name,
    tax: payload.tax,
    storeGroupId: payload.group,
    mallId: payload.id,
  };
  const res = yield call(axiosInstance.post, `/admins/stores`, body);
  if (res?.response?.data?.userMessage) {
    showNotification({
      content: res?.response?.data?.userMessage,
      type: 'error',
    });
  }
  if (res.createdAt) {
    showNotification({ content: 'Store Created', type: 'success' });
    payload.handler(false);
  }
}
