// src/services/Redux/storeIncomeReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setStoreIncome = (payload) => createAction(ActionTypes.SET_STORE_INCOME, payload);
