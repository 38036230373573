// src/pages/Income/IncomeMobile.jsx
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Header } from '../../components/Header/Header';
import { LeftSideMenu } from '../../components/navBar/Pc/LeftMenu';
import { getStoreIncome } from '../../services/Saga/storeIncome/actions';
import { Colors } from '../../utils/colors';
import './style.scss';
import MonthsComponent from '../../components/MonthsComponent';
import IncomeCalendarCell from './components/IncomeCalendarCell';
import YearPicker from '../../components/YearPicker';
import { ROLES } from '../AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';

export const IncomeMobile = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const { state } = useLocation();
  const [filteredData, setFilteredData] = useState([]);
  const user = useSelector((state) => state.loginReducer.user);
  const dispatch = useDispatch();
  const storeIncome = useSelector(
    (state) => state.storeIncomeReducer.storeIncome,
  );
  const isStoreManager =
    user.roles.length && user.roles[0] === ROLES.STORE_MANAGER;
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState({
    name: dayjs().format('MMM'),
    number: dayjs().month() + 1,
  });

  // Filter and format data based on the current and previous year
  useEffect(() => {
    let filteredThisYear = [];
    const filteredPreviousYear = [];
    let filtered = [];
      const currentYear = date?.[0]?.$y || dayjs().$y;

      const arrayThisYear = storeIncome?.data?.filter(
      (item) => dayjs(item.date).get('year') == currentYear,
      );

      const arrayPreviousYear = storeIncome?.data?.filter(
      (item) => dayjs(item.date).get('year') == currentYear - 1,
      );

    const filteredArrayThisYear = arrayThisYear?.filter((item) => {
      if (
        [53, 52].indexOf(item?.week) > -1 &&
        dayjs(item?.date).get('month') == 0
      )
        return false;
      return item;
    });

      arrayPreviousYear?.push(
        ...arrayThisYear?.filter(
        (item) =>
          [53, 52].indexOf(item?.week) > -1 &&
          dayjs(item?.date).get('month') == 0,
      ),
      );
    for (let index = 0; index <= 53; index++) {
      let arr = filteredArrayThisYear?.filter((item) => item.week == index);
      if (arr?.length) {
        const test = arr?.map((item) => {
          return { ...item, date: new Date(item.date) };
        });
        filteredThisYear.push({
          key: index,
          data: test,
        });
      }
    }
      const has52WeekAnd1Week =
      filteredArrayThisYear?.filter((item) => item.week == 52).length > 0 &&
      filteredArrayThisYear?.filter((item) => item.week == 1).length > 0;

      if (has52WeekAnd1Week) {
        const filtered = filteredThisYear.filter((x) => x.key === 1);
        filteredThisYear = filteredThisYear.filter((x) => x.key !== 1);
      console.log({ filtered, filteredThisYear });
        filteredThisYear.push(...filtered);
      }

    for (let index = 53; index > 0; index--) {
      let arr = arrayPreviousYear?.filter((item) => item.week == index);
      if (arr?.length) {
        const test = arr?.map((item) => {
          return { ...item, date: new Date(item.date) };
        });
        filteredPreviousYear.push({
          key: index,
          data: test,
        });
      }
    }

    filtered = [...filteredPreviousYear.reverse(), ...filteredThisYear];
    setFilteredData(filtered);
  }, [storeIncome.data]);

  // Set date and month based on location state
  useEffect(() => {
    if (state?.date?.length) {
      setDate([dayjs(state.date[0].$d), dayjs(state.date[1].$d)]);
      setSelectedMonth({
        name: dayjs(state.date[0].$d).format('MMM'),
        number: dayjs(state.date[0].$d).month() + 1,
      });
    }
  }, [state]);

  // Fetch store income data
  useEffect(() => {
      if (!state?.item?.id && !user.storeId) return;
    if (!date && !state?.date?.length) {
      const year = dayjs().$y;
      const month = dayjs().month() + 1;

      const beginOfMonth = dayjs(`${year}-${month}-01`).startOf('month');
      const endOfMonth = dayjs(`${year}-${month}-01`).endOf('month');

      setSelectedMonth({
        name: dayjs(dayjs().$d).format('MMM'),
        number: dayjs().month() + 1,
      });

      return dispatch(
        getStoreIncome({
          id: state?.item?.id || user.storeId,
          date: [beginOfMonth, endOfMonth],
        }),
      );
    }
    dispatch(
      getStoreIncome({
        id: state?.item?.id || user.storeId,
        date: date || [dayjs(state.date[0].$d), dayjs(state.date[1].$d)],
      }),
    );
  }, [date]);

  // Update filtered data when income is added
  const onStoreIncomeAdded = (weekIndex, index, res) => {
    setFilteredData((p) => {
      const copy = [...p];
      copy[weekIndex].data[index] = res;
      return copy;
    });
  };

  const onClickYear = (year) => {
    const beginOfMonth = dayjs(`${year}-${selectedMonth.number}-01`).startOf(
      'month',
    );
    const endOfMonth = dayjs(`${year}-${selectedMonth.number}-01`).endOf(
      'month',
    );
    setDate([beginOfMonth, endOfMonth]);
  };

  // Handle month change
  const onMonthChange = (month) => {
    setSelectedMonth(month);
    const beginOfMonth = dayjs(
      `${date?.[0]?.$y || dayjs().$y}-${month.number}-01`,
    ).startOf('month');
    const endOfMonth = dayjs(
      `${date?.[0]?.$y || dayjs().$y}-${month.number}-01`,
    ).endOf('month');
    setDate([beginOfMonth, endOfMonth]);
  };

  return (
    <>
      <Header
        title={isStoreManager ? t('report_Income') : undefined}
        goBack={{
          title: t('reporting_List'),
          onClick: () => navigate(ROUTES.REPORTS.LIST),
        }}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <LeftSideMenu selected={state?.selected || 'INCOME'} />
        <div
          className='mallContainer'
          style={{
            backgroundColor: Colors.mainBlack,
          }}
        >
          {isStoreManager && !user.storeId ? (
            <h2>{t('no_store_assigned')}</h2>
          ) : (
            <>
              <div className='IncomeHeader'>
                <div className='IncomeHeader__info'>
                  <div>
                    <span className='text'>Mall: </span>
                    <span className='name'>
                      {state?.item?.mallName || user?.store?.mall.name}
                    </span>
                  </div>
                  <div>
                    <span className='text'>Store: </span>
                    <span className='name'>
                      {state?.item?.name || user?.store?.name}
                    </span>
                  </div>
                  <YearPicker value={date?.[0]?.$y} onChange={onClickYear} />
                </div>
                <MonthsComponent
                  selectedMonth={selectedMonth}
                  setSelectedMonth={onMonthChange}
                  selectedYear={date?.[0]?.$y || dayjs().$y}
                />
              </div>
              <div>
              <div className='incomeWrapper'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                <div className='incomeBodyContainer'>
                  <div className='weekDaysContainer'>
                    <text className='weekDays'>{t('monday')}</text>
                    <text className='weekDays'>{t('tuesday')}</text>
                    <text className='weekDays'>{t('wednesday')}</text>
                    <text className='weekDays'>{t('thursday')}</text>
                    <text className='weekDays'>{t('friday')}</text>
                    <text className='weekDays'>{t('saturday')}</text>
                    <text className='weekDays'>{t('sunday')}</text>
                  </div>
                    </div>
                    <div>
                      {filteredData.map((item, weekIndex) => (
                    <div key={weekIndex} className='incomeCalendarRow'>
                      <div className='incomeCalendarRow__first'>
                            <text className='incomeCalendarRow__first--excluded'>
                              {t('excluded')}
                            </text>
                            <text className='incomeCalendarRow__first--included'>
                              {t('included')}
                            </text>
                      </div>
                          <div
                            className='incomeCalendarRow__data'
                            style={
                              weekIndex === 0
                                ? { justifyContent: 'flex-end' }
                                : {}
                            }
                          >
                            {item.data.map((item, index) => (
                          <IncomeCalendarCell
                            item={item}
                            key={index}
                            storeInfo={state?.item || user?.store}
                                onIncomeAdded={(res) =>
                                  onStoreIncomeAdded(weekIndex, index, res)
                                }
                          />
                        ))}
                      </div>
                      <div className='incomeCalendarRow__nameWeek'>
                        <text className='weekDays'>
                              {t('week')} {item.key}
                        </text>
                      </div>
                    </div>
                  ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
