// src/utils/deviceDetection.js

export const isMobileDevice = () => {
  if (process.env.REACT_APP_DEVICE === 'mobile') {
    return true;
  }
  if (process.env.REACT_APP_DEVICE === 'desktop') {
    return false;
  }
  const mobileDeviceRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileDeviceRegex.test(navigator.userAgent);
};