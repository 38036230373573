// src/services/Axios/axiosInstance.js

import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';
import setupInterceptorsTo from './setupInterceptors';

// Create an axios instance with default settings
const axiosInstance = axios.create({
  baseURL: `${MAIN_URL}/v1/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Apply interceptors to the axios instance
setupInterceptorsTo(axiosInstance);

export default axiosInstance;
