import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { Header } from '../../../components/Header/Header';
import { LeftSideMenuMobile } from '../../../components/navBar/Mobile';
import VerticalWheelPicker from '../../../components/WheelPicker/Vertical/VerticalWheelPicker';
import { getReportingList } from '../../../services/Saga/getReportingList/actions';
import { getAllMalls } from '../../../services/Saga/filters/actions';
import { setFilters as setReduxFilters } from '../../../services/Redux/reportingListReducer/action';
import ROUTES from '../../../routes/route';
import { months } from '../../../utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.scss';

const StoreOption = React.memo(
  React.forwardRef(({ store, filters, onClick }, ref) => (
    <div className="store-option" onClick={onClick} ref={ref}>
      <span className="store-option-name">{store.name}</span>
      <span className="store-option-mall">ÅmotSenteret</span>
      <span className="store-option-heading">Days Missing</span>
      <div className="store-option-details">
        <span className="store-option-text">Income: {store.selectedRangeMissingDays}</span>
        <span className="store-option-total-label">Total:</span>
        <span className="store-option-text">Customer: 29</span>
        <span className="store-option-total">{store.totalMissingDays}</span>
      </div>
      <span className="store-option-month">{filters.selectedMonth.name}</span>
    </div>
  ))
);

const calculateMissingDays = (startDate, endDate, reportedDates = [], selectedStartDate = null, selectedEndDate = null) => {
  if (!startDate) return { totalMissingDays: 0, selectedRangeMissingDays: 0 };

  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const totalDays = end.diff(start, 'day') + 1;

  const reportedDaysSet = new Set(reportedDates.map((date) => dayjs(date).format('YYYY-MM-DD')));
  const allDays = Array.from({ length: totalDays }, (_, i) => start.add(i, 'day').format('YYYY-MM-DD'));

  const totalMissingDays = allDays.filter((day) => !reportedDaysSet.has(day)).length;

  let selectedRangeMissingDays = 0;
  if (selectedStartDate && selectedEndDate) {
    const selectedStart = dayjs(selectedStartDate);
    const selectedEnd = dayjs(selectedEndDate);
    const selectedDays = allDays.filter(day => {
      const dayObj = dayjs(day);
      return dayObj.isAfter(selectedStart.subtract(1, 'day')) && dayObj.isBefore(selectedEnd.add(1, 'day'));
    });
    selectedRangeMissingDays = selectedDays.filter((day) => !reportedDaysSet.has(day)).length;
  }

  return { totalMissingDays, selectedRangeMissingDays };
};



const ReportingListM = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const observer = useRef();
  const reportingList = useSelector(state => state.reportingListReducer.reportingList);
  const reportingListTotal = useSelector(state => state.reportingListReducer.total);
  const allMalls = useSelector(state => state.filtersReducer.allMalls);
  const filters = useSelector(state => state.reportingListReducer.filters);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  const updateFilters = useCallback((newFilters) => {
    dispatch(setReduxFilters(newFilters));
  }, [dispatch]);

  const initializeFilters = useCallback(() => {
    const currentMonth = dayjs().month() + 1;
    const currentYear = dayjs().year();
    const start = dayjs(`${currentYear}-${currentMonth}-01`).startOf('month');
    const end = dayjs(`${currentYear}-${currentMonth}-01`).endOf('month');
    return {
      selectedMonth: { name: dayjs().format('MMM'), number: currentMonth.toString().padStart(2, '0') },
      selectedDates: [start, end],
    };
  }, []);

  // Fetch malls on component mount
  useEffect(() => {
    dispatch(getAllMalls({ order: 'ASC' }));
  }, [dispatch]);

  // Set initial filters once malls are fetched
  useEffect(() => {
    if (allMalls?.data?.length > 0) {
      const defaultMallId = filters.selectedMallId || allMalls.data[0].id;
      const initialFilters = initializeFilters();
      updateFilters({ ...initialFilters, selectedMallId: defaultMallId });
    }
  }, [allMalls, filters.selectedMallId, updateFilters, initializeFilters]);

  // Fetch reporting list whenever filters change
  useEffect(() => {
    if (filters.selectedMallId && filters.selectedMonth && filters.selectedDates) {
      const fetchStores = async () => {
        setIsLoading(true);
        setError(null);
        try {
          await dispatch(getReportingList({ filters, page }));
        } catch (error) {
          setError('Failed to fetch reporting list');
          console.error('Error fetching stores:', error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchStores();
    }
  }, [filters, page, dispatch]);

  useEffect(() => {
    setHasMore(reportingListTotal > reportingList?.length);
  }, [reportingList, reportingListTotal]);

  const lastVisible = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const handleFilterChange = (newFilters) => {
    updateFilters(newFilters);
    setPage(1);
  };

  const reportingListWithDateRange = useMemo(() => {
    return reportingList.map(store => {
      const today = dayjs().format('YYYY-MM-DD');
      const defaultStartDate = '2004-01-01';
      let startDate = store.startDate ? dayjs(store.startDate).format('YYYY-MM-DD') : defaultStartDate;
      let endDate = store.endDate ? dayjs(store.endDate).format('YYYY-MM-DD') : today;
      return {
        ...store,
        startDate,
        endDate,
        reportedDates: store.reportedDates || [],
      };
    });
  }, [reportingList]);

  const { activeStores, inactiveStores } = useMemo(() => {
    const activeStores = [];
    const inactiveStores = [];
    const selectedStart = dayjs(filters.selectedDates[0]);
    const selectedEnd = dayjs(filters.selectedDates[1]);

    reportingListWithDateRange.forEach((store) => {
      const storeStartDate = dayjs(store.startDate);
      const storeEndDate = store.endDate ? dayjs(store.endDate) : dayjs();

      const isActive = storeStartDate.isBefore(selectedStart.add(1, 'day')) && storeEndDate.isAfter(selectedStart.subtract(1, 'day'));

      const { totalMissingDays, selectedRangeMissingDays } = calculateMissingDays(store.startDate, store.endDate, store.reportedDates || [], filters.selectedDates[0], filters.selectedDates[1]);

      const storeWithMissingDays = {
        ...store,
        totalMissingDays,
        selectedRangeMissingDays,
      };

      if (isActive) {
        activeStores.push(storeWithMissingDays);
      } else {
        inactiveStores.push(storeWithMissingDays);
      }
    });

    activeStores.sort((a, b) => b.totalMissingDays - a.totalMissingDays);
    inactiveStores.sort((a, b) => b.totalMissingDays - a.totalMissingDays);

    return { activeStores, inactiveStores };
  }, [reportingListWithDateRange, filters.selectedDates]);

  const navigateToStoreReport = (selectedStoreId) => {
    const selectedStore = reportingList.find(store => store.id === selectedStoreId);
    navigate(ROUTES.REPORTS.INCOME_LIST, { state: { item: selectedStore, selected: 'LIST', date: filters.selectedDates } });
  };

  return (
    <>
      <Header />
      <div className='mainContainer'>
        <div className='containerMenuMobile'>
          <h2>Reporting List</h2>
          {isLoading && <div>Loading...</div>}
          {error && <div className='error'>{error}</div>}
          {!isLoading && !error && (
            <Swiper
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              style={{ width: '100%', height: 'auto' }}
            >
              <SwiperSlide style={{ width: 'auto', height: 'auto' }}>
                <div className='stores-grid'>
                  <h3>Active Stores</h3>
                  <div className='stores-column'>
                    {activeStores.map((store, index) => (
                      <StoreOption
                        key={index}
                        store={store}
                        filters={filters}
                        onClick={() => navigateToStoreReport(store.id)}
                        ref={index === activeStores.length - 1 ? lastVisible : null}
                      />
                    ))}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto', height: 'auto' }}>
                <div className='stores-grid'>
                  <h3>Inactive Stores</h3>
                  <div className='stores-column'>
                    {inactiveStores.map((store, index) => (
                      <StoreOption
                        key={index}
                        store={store}
                        filters={filters}
                        onClick={() => navigateToStoreReport(store.id)}
                        ref={index === inactiveStores.length - 1 ? lastVisible : null}
                      />
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </div>
      <div className='leftSideMenuAndDateWheelContainer'>
        <div className='dateWheelContainer'>
          <div className='verticalPickerContainer'>
            <div className="mall-picker">
              {allMalls?.data?.length > 0 && (
                <VerticalWheelPicker 
                  options={allMalls.data.map(mall => ({ value: mall.id, label: mall.name }))} 
                  selectedValue={filters.selectedMallId} 
                  onChange={mallId => handleFilterChange({ selectedMallId: mallId })} 
                />
              )}
            </div>
            <div className="month-picker">
              <VerticalWheelPicker 
                options={months().map(month => ({ label: month.name, value: month.number })).reverse()} 
                selectedValue={filters.selectedMonth.number} 
                onChange={month => {
                  const selectedYear = filters.selectedDates?.[0]?.year();
                  const start = dayjs(`${selectedYear}-${month.toString().padStart(2, '0')}-01`).startOf('month');
                  const end = dayjs(`${selectedYear}-${month.toString().padStart(2, '0')}-01`).endOf('month');
                  handleFilterChange({ selectedMonth: { name: dayjs().month(month - 1).format('MMM'), number: month.toString().padStart(2, '0') }, selectedDates: [start, end] });
                }} 
              />
            </div>
            <div className="year-picker">
              <VerticalWheelPicker 
                options={Array.from({ length: 25 }, (_, i) => {
                  const year = dayjs().year() - 20 + i;
                  return { label: year.toString(), value: year.toString() };
                }).reverse()} 
                selectedValue={filters.selectedDates?.[0]?.year().toString()} 
                onChange={year => {
                  const currentMonth = filters.selectedMonth.number;
                  const start = dayjs(`${year}-${currentMonth}-01`).startOf('month');
                  const end = dayjs(`${year}-${currentMonth}-01`).endOf('month');
                  handleFilterChange({ selectedDates: [start, end] });
                }} 
              />
            </div>
          </div>
        </div>
        <div className='leftSideMenuMobile'>
          <LeftSideMenuMobile />
        </div>
      </div>
    </>
  );
};

export default ReportingListM;