// src/services/Axios/setupInterceptors.js

import axios from 'axios';
import { MAIN_URL } from '../../utils/constants';
import { store } from '../index';
import { logOutRed, setUser } from '../Redux/loginReducer/action';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const onRequest = (config) => {
  const storageData = store.getState().loginReducer;
  if (storageData?.accessToken) {
    config.headers.Authorization = `Bearer ${storageData.accessToken}`;
  }
  return config;
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response) => response.data;

const onResponseError = (axiosInstance) => async (error) => {
  const originalRequest = error.config;

  // Ensure error.response and error.response.status are defined
  if (error.response && error.response.status) {
    if (error.response.status === 401) {
      return store.dispatch(logOutRed());
    }
    if (error.response.status !== 403) {
      return Promise.reject(error);
    }

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const loginReducer = store.getState().loginReducer;

      try {
        const { data } = await axios.put(
          `${MAIN_URL}/v1/auth/refresh-tokens`,
          {},
          { headers: { 'x-refresh-token': loginReducer?.refreshToken } }
        );
        isAlreadyFetchingAccessToken = false;
        store.dispatch(setUser(data));
        onAccessTokenFetched(data.accessToken);
      } catch (refreshError) {
        store.dispatch(logOutRed());
        return Promise.reject(refreshError);
      }
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axiosInstance(originalRequest));
      });
    });

    return retryOriginalRequest;
  } else {
    // Handle cases where error.response or error.response.status is undefined
    return Promise.reject(new Error('Response or status is undefined'));
  }
};


// Setup interceptors to the axios instance
const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError(axiosInstance));
  return axiosInstance;
};

export default setupInterceptorsTo;
