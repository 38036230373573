// src/services/Redux/loginReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setUser = (payload) => createAction(ActionTypes.SET_USER, payload);
export const setError = (payload) => createAction(ActionTypes.SET_ERROR, payload);

// Action creator for logging out does not require payload
export const logOutRed = () => ({ type: ActionTypes.LOG_OUT });

export const setIsLoading = (payload) => createAction(ActionTypes.SET_IS_LOADING, payload);
