// src/pages/AdminPanel/pages/user/UsersTable/index.jsx

import React from 'react';
import useUsersTable from './useUsersTable';
import { Colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { LeftSideMenu } from '../../../../../components/navBar/Pc/LeftMenu';
import { Header } from '../../../../../components/Header/Header';
import AdminTable from '../../../components/AdminTable';
import { Tooltip } from '@mui/material';
import AdminTablesController from '../../../components/AdminTablesController';
import AddUser from '../../../components/modals/AddUser';
import AddNewButton from '../../../components/AddNewButton';
import SearchInput from '../../../components/SearchInput';
import { Link } from 'react-router-dom';
import { getUserInfoLink } from '../../../../../routes/route';
import { formatUsers } from '../../../utils/formatForTables';

function UsersTable() {
  const {
    users,
    pagination,
    searchText,
    isModalVisible,
    setIsModalVisible,
    setSearchText,
    onPageChange,
    onUserCreated,
  } = useUsersTable();
  const { t } = useTranslation();

  const usersColumns = [
    {
      title: 'Nº',
      dataIndex: 'index',
      render: (text) => <span className='adminTable__grey'>{text}</span>,
      width: 60,
    },
    {
      title: t('adminTable.userName'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div>
          <Link to={getUserInfoLink(record.id)}>{text}</Link>
        </div>
      ),
      width: 260,
    },
    {
      title: t('adminTable.phoneNumber'),
      dataIndex: 'phone',
      render: (text) => <div>{text}</div>,
      width: 240,
    },
    {
      title: t('adminTable.email'),
      dataIndex: 'email',
      render: (text) => <div>{text}</div>,
      width: 240,
    },
    {
      title: t('adminTable.userRole'),
      dataIndex: 'role',
      render: (text) => <div>{t('adminTable.' + text)}</div>,
      width: 160,
    },
  ];

  return (
    <>
      <Header />
      <AddUser
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
        onUserCreated={onUserCreated}
      />
      <div
        className='mainContainer'
        style={{ backgroundColor: Colors.mainBlack }}
      >
        <LeftSideMenu selected='ADMIN' />
        <div style={{ marginLeft: 12, width: 'calc(100% - 60px)' }}>
          <AdminTable
            columns={usersColumns}
            dataSource={users}
            pagination={pagination}
            onPaginationChange={onPageChange}
            header={
              <div style={{ margin: '16px 0' }}>
                <div className='adminTable__header'>
                  <AdminTablesController />
                  <AddNewButton
                    text={t('add_New_User')}
                    onClick={() => setIsModalVisible(true)}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SearchInput value={searchText} onChange={setSearchText} />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default UsersTable;
