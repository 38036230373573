// src/services/index.js

import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import rootReducer from './Redux';
import rootSaga from './Saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(rootSaga);
const dispatch = store.dispatch;

export { store, dispatch };
