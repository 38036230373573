// src/components/IncomeTable/const.js

import dayjs from 'dayjs';
import generateUID from './utils/generateUID';

// Default properties for standard columns
export const standartColumnProperties = {
  isVisible: true,
  tax: 'excluded',
  type: 'income',
};

// Default properties for diff columns
export const diffColumns = [
  {
    isDiff: true,
    diffType: 'numbers',
    columnUID: generateUID(),
    isVisible: true,
  },
  {
    isDiff: true,
    diffType: 'percentage',
    columnUID: generateUID(),
    isVisible: true,
  },
];

// Function to generate default columns
export const generateDefaultColumns = () => [
  {
    from: dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
    to: dayjs().subtract(1, 'year').endOf('month').format('YYYY-MM-DD'),
    columnUID: generateUID(),
    ...standartColumnProperties,
  },
  {
    from: dayjs().startOf('month').format('YYYY-MM-DD'),
    to: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    columnUID: generateUID(),
    isAccumulated: true,
    ...standartColumnProperties,
  },
  ...diffColumns,
];

// Function to generate default table configuration
export const generateDefaultTable = () => ({
  columns: generateDefaultColumns(),
  hiddenStores: [],
  hiddenMalls: [],
  minimizedMalls: [],
  sort: '',
  sortedByColumnUID: null,
  filters: {
    storeGroups: [],
    storeGroupId: null,
  },
});

// Default columns and tables for export
export const defaultColumns = generateDefaultColumns();
export const defaultTables = {
  [generateUID()]: generateDefaultTable(),
};

// Sort options
export const SORT = ['', 'ASC', 'DESC'];
