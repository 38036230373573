// src/components/IncomeTable/utils/otherUtils.js

export const shouldDisplayPercents = (columns, columnUID, cellData) => {
  const column = columns.find((x) => x.columnUID === columnUID);
  return column.isDiff && column.diffType === 'percentage' && cellData !== '-';
};

export const getIsStoreShown = (
  { hiddenStores, columns, hiddenMalls },
  columnUID,
  storeId,
  mallId,
) => {
  const column = columns.find((x) => x.columnUID === columnUID);
  if (
    hiddenStores.find((x) => x === storeId) ||
    hiddenMalls.find((x) => x === mallId)
  )
    return 'hidden';
  if (!column?.isVisible && columnUID !== null) return 'hidden';

  return '';
};
