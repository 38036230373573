// src/pages/AdminPanel/components/AdminTable/index.jsx

import React from 'react';
import { Table } from 'antd';
import './styles.scss';
import AdminTablePagination from '../AdminTablePagination';

function AdminTable({
  columns,
  dataSource,
  header = <></>,
  onPaginationChange,
  pagination,
}) {
  return (
    <div className='adminTable'>
      {header}
      <div className='adminTable__inner'>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
      <div>
        <AdminTablePagination
          onPaginationChange={onPaginationChange}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

export default AdminTable;
