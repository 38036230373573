// src/assets/icons/ExportNew.jsx

import * as React from 'react';
const ExportNew = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='M13.125 6.875h1.563a1.563 1.563 0 0 1 1.562 1.563v8.124a1.563 1.563 0 0 1-1.563 1.563H5.313a1.563 1.563 0 0 1-1.563-1.563V8.438a1.562 1.562 0 0 1 1.563-1.562h1.562'
    />
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='M6.875 10.625 10 13.75l3.125-3.125M10 1.875v11.25'
    />
  </svg>
);
export default ExportNew;
