// src/pages/AdminPanel/components/SearchInput/index.jsx

import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function SearchInput({ value, onChange }) {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = React.useState('');

  React.useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const debouncedOnChange = React.useCallback(debounce(onChange), []);

  const onInnerValueChange = (e) => {
    const value = e.target.value;
    setInnerValue(value);
    debouncedOnChange(value);
  };

  return <></>;

  return (
    <div className='Input'>
      <SearchOutlined />
      <input
        onChange={onInnerValueChange}
        value={innerValue}
        placeholder={t('search')}
      />
    </div>
  );
}

export default SearchInput;
