// src/components/IncomeTable/components/Tables/useTables.jsx

import React, { useContext, useState, useEffect } from 'react';
import { IncomeTableContext } from '../../IncomeTableContext';
import _ from 'lodash';

const TABLE_MARGIN = 56;

function useTables() {
  const { tablesSettings, selectedTable, animation, setAnimation } = useContext(IncomeTableContext);
  const [tablesWithDelay, setTablesWithDelay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tables = Object.keys(tablesSettings);

  // Update tablesWithDelay when tablesSettings changes
  useEffect(() => {
    const tablesValues = Object.values(tablesSettings);
    if (tablesValues.length > 0 && tablesValues.every((x) => x.malls) && tablesWithDelay.length === 0) {
      if (!_.isEqual(tablesWithDelay, tables)) setTablesWithDelay([...tables]);
    }
    if (tablesValues.length === 0) {
      setTablesWithDelay([]);
    }
  }, [tablesSettings]);

  // Set loading state when tablesWithDelay changes
  useEffect(() => {
    setIsLoading(true);
    if (tablesWithDelay.length) {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [tablesWithDelay]);

  // Handle table movement and animation
  useEffect(() => {
    if (!tables.length || !tablesWithDelay.length) return;

    if (tables.length === tablesWithDelay.length) {
      if (selectedTable && !_.isEqual(tablesWithDelay, tables)) {
        moveTable(tables, tablesWithDelay, selectedTable, setAnimation, setTablesWithDelay, isLoading);
      } else {
        setTimeout(() => {
          tables.forEach((tableUID) => {
            const tableEl = document.getElementById(tableUID);
            if (tableEl) tableEl.style.top = getTopOffset(tables, tableUID) + 'px';
          });
        }, 300);
      }
    } else {
      setTablesWithDelay(tables);
    }
  }, [tablesSettings, tablesWithDelay, isLoading, selectedTable, tables]);

  return {
    animation,
    getClassName,
    getTopOffset,
    TABLE_MARGIN,
    tablesWithDelay,
    isLoading,
    selectedTable,
  };
}

export default useTables;

// Helper function to get the CSS class name based on table selection
const getClassName = (currentTableUID, selectedTableUID) => {
  if (currentTableUID !== selectedTableUID) return 'opacityGray';
};

// Helper function to calculate the top offset of a table
const getTopOffset = (tables = [], currentTableUID) => {
  const tableIndex = tables.findIndex((tableUID) => currentTableUID === tableUID);
  if (tableIndex === 0) return 0;

  let totalHeight = 0;
  for (let index = 0; index < tableIndex; index++) {
    const el = document.getElementById(tables[index]);
    totalHeight += el ? el.clientHeight + TABLE_MARGIN : TABLE_MARGIN;
  }
  return totalHeight;
};

// Helper function to handle table movement and animation
const moveTable = (tables, tablesWithDelay, selectedTable, setAnimation, setTablesWithDelay, isLoading) => {
  const el = document.getElementById(selectedTable);
  if (!el) return;

  el.style.top = getTopOffset(tables, selectedTable) + 'px';
  el.style.zIndex = 20;

  const newSelectedTableIndex = tables.findIndex((tableUID) => selectedTable === tableUID);
  const oldSelectedTableIndex = tablesWithDelay.findIndex((tableUID) => selectedTable === tableUID);
  const bottom = newSelectedTableIndex > oldSelectedTableIndex
    ? tables[newSelectedTableIndex - 1]
    : tables[newSelectedTableIndex + 1];

  document.getElementById(bottom).style.top = getTopOffset(tables, bottom) + 'px';

  setTimeout(() => {
    setAnimation(null);
    if (!isLoading) setTablesWithDelay(tables);
    el.style.zIndex = 1 + newSelectedTableIndex;
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 1200);
};
