// src/services/Redux/totalIncomeCardsReducer/action.js

import { ActionTypes } from './actionTypes';

// Reusable function to create action objects
const createAction = (type, payload) => ({
  type,
  payload,
});

export const setTotalIncomeCard = (payload) => createAction(ActionTypes.SET_TOTAL_INCOME_CARD, payload);
export const setTopStoreCard = (payload) => createAction(ActionTypes.SET_TOP_STORE_CARD, payload);
export const setTopMallCard = (payload) => createAction(ActionTypes.SET_TOP_MALL_CARD, payload);
export const setTotalIncomeAmount = (payload) => createAction(ActionTypes.SET_TOTAL_INCOME_AMOUNT, payload);
export const clearTotalIncomeAmount = (payload) => createAction(ActionTypes.CLEAR_TOTAL_INCOME_AMOUNT, payload);
export const setTopPercentageGrowth = (payload) => createAction(ActionTypes.SET_TOP_PERCENTAGE_GROWTH, payload);
export const setConsolidatedIncome = (payload) => createAction(ActionTypes.SET_CONSOLIDATED_INCOME, payload);
export const setConsolidatedFood = (payload) => createAction(ActionTypes.SET_CONSOLIDATED_FOOD, payload);
export const setTax = (payload) => createAction(ActionTypes.SET_TAX, payload);
