// src/utils/addSpaces.js

const defaultOptions = { emptyValue: '-', hideSecond: false };

const addSpaces = (value, options = {}) => {
  const { emptyValue, hideSecond } = { ...defaultOptions, ...options };

  try {
    if (isNaN(Number(value)) || value === null) return emptyValue;

    let [integerPart, decimalPart] = value.toString().split('.');

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    if (decimalPart?.length > 2) {
      decimalPart = decimalPart.slice(0, 2);
    }

    if (hideSecond) return integerPart;

    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  } catch (error) {
    console.error('Error formatting value: ', value, error);
    return emptyValue;
  }
};

export default addSpaces;
