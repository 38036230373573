// src/services/Redux/loginReducer/index.js

import { ActionTypes } from './actionTypes';

const initialState = {
  accessToken: '',
  refreshToken: '',
  user: {},
  err: false,
  isLoading: true,
};

// Reducer to handle login actions
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        accessToken: action.payload.accessToken || state.accessToken,
        refreshToken: action.payload.refreshToken || state.refreshToken,
        user: action.payload.user || state.user,
        isLoading: false,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        err: action.payload,
      };
    case ActionTypes.LOG_OUT:
      return { ...initialState, isLoading: false };
    case ActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
