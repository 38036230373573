// src/services/Saga/getTotalIncomeAmount/actions.js

import { ActionTypes } from './actionsTypes';

export const getTotalIncomeAmount = (payload) => ({
  type: ActionTypes.GET_TOTAL_INCOME_AMOUNT,
  payload,
});
export const exportTotalIncomeAmount = (payload) => ({
  type: ActionTypes.EXPORT_TOTAL_INCOME_AMOUNT,
  payload,
});
