// src/components/IncomeTable/components/TableController/index.jsx

import React, { useContext, useEffect, useState } from 'react';
import Settings from '../../../../assets/icons/Settings';
import Add from '../../../../assets/icons/Add';
import Delete from '../../../../assets/icons/Delete';
import ExportNew from '../../../../assets/icons/ExportNew';
import FilterNew from '../../../../assets/icons/FilterNew';
import HideUp from '../../../../assets/icons/HideUp';
import HideDown from '../../../../assets/icons/HideDown';
import { IncomeTableContext } from '../../IncomeTableContext';
import './styles.scss';
import { Popover, Tooltip } from 'antd';
import Filters from '../Filters';
import isElementInViewport, { isElementFullyInViewport } from '../../utils/isInViewport';
import { useTranslation } from 'react-i18next';
import exportTable from '../../utils/exportTable';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../../pages/AdminPanel/utils/formatForTables';
import ExportProgress from '../ExportProgress';

let lastScrolledToTable = null;

function TableController() {
  const {
    selectedTable,
    toggleEditing,
    addTable,
    deleteTable,
    isEditing,
    tablesSettings,
    lastVisibleTable,
    setLastVisibleTable,
    moveTable,
    animation,
  } = useContext(IncomeTableContext);

  const { t } = useTranslation();
  const user = useSelector((state) => state.loginReducer.user);
  const isStoreManager = user.roles[0] === ROLES.STORE_MANAGER;

  const [isLastTableFullyShown, setIsLastTableFullyShown] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      getIsLastTableFullyShown();
      let foundVisible = false;
      Object.keys(tablesSettings).forEach((tableUID) => {
        if (foundVisible) return;
        if (isElementInViewport(document.getElementById(tableUID))) {
          setLastVisibleTable(tableUID);
          foundVisible = true;
        }
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [tablesSettings]);

  const activateEdit = () => {
    if (!selectedTable) return;
    toggleEditing();
  };

  const exportTables = async () => {
    if (progress !== 0 || selectedTable || isStoreManager) return;
    setProgress(1);
    exportTable(tablesSettings, setProgress).finally(() => setProgress(0));
  };

  const deleteTableClick = () => {
    if (!selectedTable) return;
    deleteTable();
  };

  const isAddDisabled = selectedTable || Object.keys(tablesSettings).length >= (isStoreManager ? 3 : 10);

  const addTableClick = () => {
    if (isAddDisabled) return;
    addTable();
  };

  const tableUIDs = Object.keys(tablesSettings);

  const disableExport = selectedTable || isStoreManager ? 'disabled' : '';
  const disableIfTableNotSelectedClassName = selectedTable ? '' : 'disabled';

  const shouldDisableScrollUp =
    animation || (selectedTable ? tableUIDs[0] === selectedTable : tableUIDs[0] === lastVisibleTable);

  const shouldDisableScrollDown =
    animation || (selectedTable ? tableUIDs[tableUIDs.length - 1] === selectedTable : tableUIDs[tableUIDs.length - 1] === lastVisibleTable || isLastTableFullyShown);

  const getIsLastTableFullyShown = () => {
    const lastTable = document.getElementById(tableUIDs[tableUIDs.length - 1]);
    setTimeout(() => {
      setIsLastTableFullyShown(lastTable ? isElementFullyInViewport(lastTable) : false);
    }, 100);
  };

  const scrollUpClassName = shouldDisableScrollUp ? 'disabled' : '';
  const scrollDownClassName = shouldDisableScrollDown ? 'disabled' : '';

  const onScrollUpClick = () => {
    if (shouldDisableScrollUp) return;
    if (selectedTable) {
      moveTable('up');
      return;
    }
    if (!shouldDisableScrollUp) {
      const prev = tableUIDs[tableUIDs.findIndex((x) => x === lastVisibleTable) - 1];
      const prevTableEl = document.getElementById(prev);
      prevTableEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onScrollDownClick = () => {
    if (shouldDisableScrollDown) return;
    if (selectedTable) {
      moveTable('down');
      return;
    }
    if (!shouldDisableScrollDown) {
      const currentTableIndex = tableUIDs.findIndex((x) => x === lastVisibleTable);
      const nextTable = currentTableIndex === 0 && lastScrolledToTable !== tableUIDs[0] ? tableUIDs[0] : tableUIDs[currentTableIndex + 1];
      lastScrolledToTable = nextTable;
      const nextTableEl = document.getElementById(nextTable);
      nextTableEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className='TableController'>
      <ExportProgress progress={progress} />
      {selectedTable ? (
        <Popover content={<Filters />} placement='left' trigger={'click'} popupVisible={selectedTable}>
          <FilterNew className={disableIfTableNotSelectedClassName} />
        </Popover>
      ) : (
        <Tooltip title={t('adminTable.controller.filters')} placement='left'>
          <FilterNew className={disableIfTableNotSelectedClassName} />
        </Tooltip>
      )}
      <Tooltip title={t('adminTable.controller.settings')} placement='left'>
        <Settings className={isEditing ? 'active' : disableIfTableNotSelectedClassName} onClick={activateEdit} />
      </Tooltip>
      <Tooltip title={t('adminTable.controller.export')} placement='left'>
        <ExportNew onClick={exportTables} className={disableExport} />
      </Tooltip>
      <Tooltip title={t('adminTable.controller.add')} placement='left'>
        <Add onClick={addTableClick} className={isAddDisabled ? 'disabled' : ''} />
      </Tooltip>
      <Tooltip title={t('adminTable.controller.delete')} placement='left'>
        <Delete className={disableIfTableNotSelectedClassName} onClick={deleteTableClick} />
      </Tooltip>
      <Tooltip
        title={selectedTable ? t('adminTable.controller.moveUp') : t('adminTable.controller.scrollUp')}
        placement='left'
      >
        <HideUp color='white' className={scrollUpClassName} onClick={onScrollUpClick} />
      </Tooltip>
      <Tooltip
        title={selectedTable ? t('adminTable.controller.moveDown') : t('adminTable.controller.scrollDown')}
        placement='left'
      >
        <HideDown color='white' className={scrollDownClassName} onClick={onScrollDownClick} />
      </Tooltip>
    </div>
  );
}

export default TableController;
