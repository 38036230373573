// src/components/IncomeTable/utils/getTotal.js

import { getNeededCellData, toNumber, toServerDate } from './getCellData';

export const getColumnTotal = (stores, column, columnsSettingsForDiff) => {
  if (column.isDiff) {
    return stores.reduce((total, store) => {
      const cellData = getNeededCellData(
        column.diffType === 'numbers' ? store.numberDiff : store.percentageDiff,
        columnsSettingsForDiff.type,
        columnsSettingsForDiff.tax,
      );

      return total + toNumber(cellData || 0);
    }, 0);
  }
  return stores.reduce((total, store) => {
    const cellData = getNeededCellData(
      store[`${toServerDate(column.from)}_${toServerDate(column.to)}`],
      column.type,
      column.tax,
    );

    return total + toNumber(cellData);
  }, 0);
};
