// src/services/Saga/storeIncome/index.js

import axios from 'axios';
import dayjs from 'dayjs';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MAIN_URL } from '../../../utils/constants';
import { setStoreIncome } from '../../Redux/storeIncomeReducer/action';
import { ActionTypes } from './actionsTypes';
import axiosInstance from '../../Axios/axiosInstance';

export function* watcherGetStoreIncome() {
  yield takeEvery(ActionTypes.GET_STORE_INCOME, workerGetStoreIncome);
}
export function* workerGetStoreIncome({ payload }) {
  const today = dayjs().format('YYYY-MM-DD');
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const start = !!payload.date
    ? payload.date[0].format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload.date ? payload.date[1].format('YYYY-MM-DD') : today;
  const res = yield call(
    axiosInstance.get,
    `/incomes/stores/${payload.id}/reports?limit=${
      payload.page ? 32 : 100
    }&from=${start}&to=${end}`,
  );
  if (res?.data) {
    yield put(setStoreIncome(res));
  }
}
export function* watcherAddIncome() {
  yield takeEvery(ActionTypes.ADD_INCOME, workerAddIncome);
}
export function* workerAddIncome({ payload }) {
  const body = {
    storeId: payload.id,
    included: payload.included,
    excluded: payload.excluded,
    date: dayjs(payload.date).format('YYYY-MM-DD'),
  };
  const putBody = {
    included: payload.included,
    excluded: payload.excluded,
  };
  // Make the request to add or update the income
  const res = payload.isEmpty
    ? yield call(axiosInstance.put, `/incomes/${payload.isEmpty}`, putBody)
    : yield call(axiosInstance.post, `/incomes/`, body);

  // If the income request was successful, update the days missing
  if (res.status === 200 || res.status === 201) {
    yield call(axiosInstance.put, `/days-missing/${payload.id}`, body);
  }
  return res;
}

export function* watcherExportIncome() {
  yield takeEvery(ActionTypes.EXPORT_INCOME, workerExportIncome);
}

export function* workerExportIncome({ payload }) {
  const token = yield select((state) => state?.loginReducer?.accessToken);
  const today = dayjs().format('YYYY-MM-DD');
  const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const start = !!payload.date
    ? payload.date[0].format('YYYY-MM-DD')
    : startMonth;
  const end = !!payload.date ? payload.date[1].format('YYYY-MM-DD') : today;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Authorization: `Bearer ${token}`,
  };
  axios({
    url: `${MAIN_URL}/v1/incomes/reports/exports/${payload.id}?from=${start}&to=${end}`,
    method: 'GET',
    headers,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}
