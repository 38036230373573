// src/services/Redux/index.js

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import createFilter from 'redux-persist-transform-filter';

import { loginReducer } from './loginReducer';
import { mallsReducer } from './mallsReducer';
import { storeIncomeReducer } from './storeIncomeReducer';
import { leftMenuReducer } from './leftMenuReducer';
import { totalIncomeCardReducer } from './totalIncomeCardsReducer';
import { reportingListReducer } from './reportingListReducer';
import { filtersReducer } from './filtersReducer';

// Filter to save only accessToken and refreshToken from loginReducer
const saveToken = createFilter('loginReducer', ['accessToken', 'refreshToken']);

const rootReducer = combineReducers({
  loginReducer,
  mallsReducer,
  storeIncomeReducer,
  leftMenuReducer,
  totalIncomeCardReducer,
  reportingListReducer,
  filtersReducer,
});

const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['loginReducer'],
  transforms: [saveToken],
};

export default persistReducer(persistConfig, rootReducer);
