// src/services/Axios/AdminApi.js

import axiosInstance from './axiosInstance';

const AdminApi = {
  // Mall related endpoints
  getMalls: (params) => axiosInstance.get('/admins/malls', { params }),
  getMallsList: (params) => axiosInstance.get('/malls', { params }),
  getMall: (id) => axiosInstance.get(`/admins/malls/${id}`),
  createMall: (data) => axiosInstance.post('/admins/malls', data),
  updateMall: (id, data) => axiosInstance.put(`/admins/malls/${id}`, data),
  deleteMall: (id) => axiosInstance.delete(`/admins/malls/${id}`),

  // User related endpoints
  getUsers: (params) => axiosInstance.get('/admins/users', { params }),
  getUser: (id) => axiosInstance.get(`/admins/users/${id}`),
  createUser: (data) => axiosInstance.post('/admins/users', data),
  updateUser: (id, data) => axiosInstance.put(`/admins/users/${id}`, data),
  deleteUser: (id) => axiosInstance.delete(`/admins/users/${id}`),

  // Store related endpoints
  getStores: (params) => axiosInstance.get('/admins/stores', { params }),
  getStore: (id) => axiosInstance.get(`/admins/stores/${id}`),
  createStore: (data) => axiosInstance.post('/admins/stores', data),
  updateStore: (id, data) => axiosInstance.put(`/admins/stores/${id}`, data),
  deleteStore: (id) => axiosInstance.delete(`/admins/stores/${id}`),

  // Category related endpoint
  getCategories: () => axiosInstance.get('/store-groups'),
};

export default AdminApi;
