// src/pages/AdminPanel/pages/store/StoreInfo/useStoreInfo.jsx

import React from 'react';
import { useNavigate, useParams } from 'react-router';
import AdminApi from '../../../../../services/Axios/AdminApi';
import ROUTES from '../../../../../routes/route';
import showNotification from '../../../../../utils/showNotification';

function useStoreInfo() {
  const [isEdit, setIsEdit] = React.useState(false);
  const [store, setStore] = React.useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] =
    React.useState(false);
  const { storeId } = useParams();
  const navigate = useNavigate();

  const fetchStore = () => {
    AdminApi.getStore(storeId).then((res) => {
      setStore(res);
    });
  };

  const changeStore = async (data) => {
    await AdminApi.updateStore(storeId, data)
      .then((res) => {
        setStore(res);
        setIsEdit(false);
      })
      .catch((err) => {
        showNotification({
          content: err?.response?.data?.userMessage,
          type: 'error',
        });
      });
    return true;
  };

  const deleteStore = async () => {
    await AdminApi.deleteStore(storeId).catch((err) => {
      showNotification({
        content: err?.response?.data?.userMessage,
        type: 'error',
      });
    });
    return true;
  };

  const onDeleteConfirm = () => {
    setIsDeleteConfirmVisible(false);
    navigate(ROUTES.ADMIN.STORES.LIST);
  };

  React.useEffect(() => {
    if (storeId) fetchStore();
  }, [storeId]);

  return {
    store,
    isEdit,
    setIsEdit,
    isDeleteConfirmVisible,
    setIsDeleteConfirmVisible,
    deleteStore,
    changeStore,
    onDeleteConfirm,
  };
}

export default useStoreInfo;
