// src/assets/icons/Pen.jsx

import * as React from 'react';

const Pen = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={10}
    height={10}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        stroke='#797979'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.7}
        d='M7.112 2.446 1.699 7.871l-.449.88.879-.45 5.425-5.413-.442-.442Zm1.105-1.104-.442.442.442.441.441-.442a.313.313 0 0 0-.441-.441Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h10v10H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default Pen;
