// src/pages/Login/Login.jsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from '../../services/Saga/loginSaga/actions';
import { Colors } from '../../utils/colors';
import './style.scss';
import { Button } from '@mui/material';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

export const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisiblePass, setIsVisiblePass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onPressLogin = () => {
    console.log('Login button pressed');
    console.log('Email:', email);
    console.log('Password:', password);
    dispatch(login({ email, password, navigate }));
  };

  useEffect(() => {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }, []);

  return (
    <div className='mainWrapper' style={{ backgroundColor: Colors.mainBlack }}>
      <div className='wrapper'>
        <div className='titleLogin' style={{ color: Colors.mainYellow }}>
          Omsetningen
        </div>
        <div className='inputsContainer' style={{ backgroundColor: Colors.mainBlack }}>
          <div className='inputEmailContainer'>
            <div className='inputTitle' style={{ color: Colors.white }}>
              {t('email')}
            </div>
            <input
              type='email'
              className='inputEmail'
              style={{ backgroundColor: Colors.mainBlack }}
              placeholder={t('email')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='inputPasswordContainer'>
            <div className='inputTitle' style={{ color: Colors.white }}>
              {t('password')}
            </div>
            <input
              className='inputPassword'
              type={isVisiblePass ? 'text' : 'password'}
              style={{ backgroundColor: Colors.mainBlack }}
              placeholder={t('password')}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              onClick={() => setIsVisiblePass(!isVisiblePass)}
              className='eyeIconContainerLogin'
            >
              {isVisiblePass ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </div>
          </div>
          <Button
            className='buttonLogin'
            style={{ color: Colors.white }}
            onClick={onPressLogin}
          >
            {t('log_in')}
          </Button>
        </div>
      </div>
    </div>
  );
};
