// src/assets/icons/FilterNew.jsx

import * as React from 'react';
const FilterNew = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeOpacity={0.72}
      d='m1.382 3.403 6.588 7.674a.628.628 0 0 1 .154.407v4.661a.31.31 0 0 0 .21.297l3.132 1.042a.31.31 0 0 0 .408-.297v-5.703a.628.628 0 0 1 .156-.407l6.586-7.674a.547.547 0 0 0-.414-.903H1.798a.547.547 0 0 0-.416.903Z'
    />
  </svg>
);
export default FilterNew;
