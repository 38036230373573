// src/components/IncomeTable/components/Filters/index.jsx

import React, { useContext } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStoresGroup } from '../../../../services/Saga/filters/actions';
import { Checkbox } from 'antd';
import { IncomeTableContext } from '../../IncomeTableContext';
import { useTranslation } from 'react-i18next';

function Filters() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const allStoresGroup = useSelector(
    (state) => state.filtersReducer.allStoresGroup,
  );
  const categories = [
    { id: null, eng: 'Select all', nor: 'Velg alle' },
    ...allStoresGroup,
  ];
  const { updateFilters, selectedTable, tablesSettings } =
    useContext(IncomeTableContext);

  const tableSettings = tablesSettings[selectedTable];

  React.useEffect(() => {
    dispatch(getAllStoresGroup());
  }, []);

  const onClickStoreGroup = (checked, id) => {
    if (checked) {
      updateFilters(selectedTable, {
        ...tableSettings.filters,
        // storeGroups: [...tableSettings.filters.storeGroups, id],
        storeGroupId: id,
      });
    } else {
      updateFilters(selectedTable, {
        ...tableSettings.filters,
        /*
        storeGroups: [
          ...tableSettings.filters.storeGroups.filter((x) => x !== id),
        ],*/
        storeGroupId: null,
      });
    }
  };

  return (
    <div className='Filters'>
      <div className='checkBoxContainer'>
        {categories?.map((item, index) => (
          <div>
            <Checkbox
              className='filterCheckBoxContainer'
              onChange={(e) => onClickStoreGroup(e.target.checked, item.id)}
              key={index}
              checked={tableSettings.filters.storeGroupId === item.id}
            >
              <text className='filterCheckBoxText'>
                {i18n.languages.some((x) => x === 'nor') ? item.nor : item.eng}
              </text>
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Filters;
