// src/components/YearPicker/index.jsx

import React from 'react';
import { Select } from 'antd';
import dayjs from 'dayjs';
import './styles.scss';
import Triangle from '../../assets/icons/Triangle';

const today = dayjs();

// Create year objects from 2010 to the current year
function createYearObjects() {
  const currentYear = today.year();
  const yearObjects = [];
  for (let year = currentYear; year >= 2010; year--) {
    yearObjects.push({ value: year, label: year });
  }
  return yearObjects;
}

const years = createYearObjects();

function YearPicker({ value, onChange }) {
  return (
    <Select
      value={value || today.year()}
      style={{ minWidth: 72 }}
      onChange={onChange}
      dropdownStyle={{ backgroundColor: 'rgb(18, 18, 18)' }}
      options={years}
      className='YearPicker'
      suffixIcon={<Triangle />}
    />
  );
}

export default YearPicker;
