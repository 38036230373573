// src/components/LeftMenu/Pc/LeftMenu.jsx

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Admin from '../../../assets/icons/Admin';
import Dashboard from '../../../assets/icons/Dashboard';
import Income from '../../../assets/icons/Income';
import List from '../../../assets/icons/List';
import { Colors } from '../../../utils/colors';
import { ROLES } from '../../../pages/AdminPanel/utils/formatForTables';

import './style.scss';

export const LeftSideMenu = ({ selected }) => {
  const user = useSelector((state) => state.loginReducer.user);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dashboardColor, setDashboardColor] = useState('#FAFAFA');
  const [listColor, setListColor] = useState('#FAFAFA');
  const [incomeColor, setIncomeColor] = useState('#FAFAFA');
  const [adminColor, setAdminColor] = useState('#FAFAFA');

  const onTitlePress = (key) => {
    navigate(key);
  };

  const renderButton = (role, route, title, Icon, color, setColor, isSelected) => {
    return user?.roles.includes(role) ? (
      <Tooltip title={t(title)} placement='right'>
        <button
          className='buttonWithPadding'
          onClick={() => onTitlePress(route)}
          onMouseEnter={() => setColor('rgba(218, 253, 0, 1)')}
          onMouseLeave={() => setColor('#FAFAFA')}
        >
          <Icon selected={isSelected} color={color} />
        </button>
      </Tooltip>
    ) : null;
  };

  return (
    <div
      style={{
        backgroundColor: Colors.mainBlack,
        width: '64px',
      }}
      className='containerMenu'
    >
      <div className='wrapperMenu'>
        <div className='iconContainer'>
          <Tooltip title={t('dashboard')} placement='right'>
            <button
              className='simpleButton'
              onClick={() => onTitlePress('/dashboard')}
              onMouseEnter={() => setDashboardColor('rgba(218, 253, 0, 1)')}
              onMouseLeave={() => setDashboardColor('#FAFAFA')}
            >
              <Dashboard selected={selected === 'HOME'} color={dashboardColor} />
            </button>
          </Tooltip>
          {renderButton(ROLES.ADMIN, '/list', 'reporting_List', List, listColor, setListColor, selected === 'LIST')}
          {renderButton(ROLES.MALL_MANAGER, '/list', 'reporting_List', List, listColor, setListColor, selected === 'LIST')}
          {renderButton(ROLES.MALL_OWNER, '/list', 'reporting_List', List, listColor, setListColor, selected === 'LIST')}
          {renderButton(ROLES.STORE_MANAGER, '/income', 'report_Income', Income, incomeColor, setIncomeColor, selected === 'INCOME')}
          {renderButton(ROLES.ADMIN, '/admin/malls', 'admin_Panel', Admin, adminColor, setAdminColor, selected === 'ADMIN')}
        </div>
      </div>
    </div>
  );
};
