// src/pages/AdminPanel/components/modals/AddMall/index.jsx

import { Button, Modal } from '@mui/material';
import React from 'react';
import AdminFormInput from '../../AdminFormInput';
import AdminFormSelect from '../../../components/AdminFormSelect';
import '../sharedStyles.scss';
import useAddMall from './useAddMall';
import GrayButton from '../../../../../components/GrayButton';

function AddMall({ isOpen, close, onMallsCreated }) {
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    t,
    touched,
    handleBlur,
    mallManagers,
    mallOwners,
  } = useAddMall({ onMallsCreated, close });

  return (
    <Modal open={isOpen} onClose={close} onBackdropClick={close}>
      <div className='adminModal'>
        <div>
          <h3>{t('add_New_Mall')}</h3>
          <div>
            <AdminFormInput
              name={`${t('adminTable.mallName')}*`}
              placeholder={t('adminTable.mallName')}
              value={values.mallName}
              onChange={(val) => setFieldValue('mallName', val)}
              error={touched.mallName && errors.mallName}
              onBlur={handleBlur('mallName')}
            />
            <AdminFormSelect
              name={t('adminTable.mallOwner')}
              placeholder={t('adminTable.mallOwner')}
              value={values.mallOwner}
              options={mallOwners.map((user) => ({
                label: user.name + ' ' + user.surname,
                value: user.id,
              }))}
              onChange={(val) => setFieldValue('mallOwner', val)}
              error={touched.mallOwner && errors.mallOwner}
              onBlur={handleBlur('mallOwner')}
            />
            <AdminFormSelect
              name={t('adminTable.mallManager')}
              placeholder={t('adminTable.mallManager')}
              value={values.mallManager}
              options={mallManagers.map((user) => ({
                label: user.name + ' ' + user.surname,
                value: user.id,
              }))}
              onChange={(val) => setFieldValue('mallManager', val)}
              error={touched.mallManager && errors.mallManager}
              onBlur={handleBlur('mallManager')}
            />
          </div>
          <div className='adminModal__buttons'>
            <GrayButton onClick={close}>{t('cancel')}</GrayButton>
            <GrayButton onClick={handleSubmit}>{t('save')}</GrayButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddMall;
